//PMU IMAGES
import PowderBrows from "../../assets/images/academy/academyPowderBrows.jpg";
import LipBlush from "../../assets/images/academy/academyLipBlush.jpg";

//BODY CONTOURING IMAGES
import AcademyBodyContouring from "../../assets/images/academy/academyBodyContouring.jpg";
import WoodTherapy from "../../assets/images/academy/academyWoodTherapy.jpg";
import IceTherapySculpting from "../../assets/images/academy/academyIceTherapySculpting.jpg";
import YesoTherapy from "../../assets/images/academy/academyYesoTherapy.jpg";

//AESTHETICS images
import AcademyDermaplaning from "../../assets/images/academy/AcademyDermaplaning.jpg";

//TEETH WHITENING IMAGES
import TeethWhitening from "../../assets/images/academy/AcademyTeethWhitening.jpg";
import ToothGem from "../../assets/images/academy/AcademyToothGem.jpg";

// Academy Course Data
export const AcademyCourseData = {
  //   ===========================================================================================================
  // PMU SECTION
  "Permanent Make Up": [
    // PMU Three day Lip Blush Course
    {
      courseId: "threeDayLipBlushCourse",
      courseName: "Three-day Lip Blush Course",
      image: LipBlush,
      alt: "Three-day Lip Blush Course Image",
      smallDescription:
        "Become a Professional Lip Blushing technician. Lip Blushing is becoming one of the most popular permanent makeup procedures. Challenge the inner artist in you and create beautiful, luscious lips",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/Lipblushcourse ",
      academyDetails: {
        longDescription:
          "Lip Blushing is a semi-permanent cosmetic tattoo that enhances the lips' natural tint and shape. It's designed to define and outline your lips, It gives the illusion of fuller lips, but in a very natural way. Lip Blushing lasts up to two years. Become a Professional Lip Blushing Technician today!",
        whatWouldYouLearnTitle:
          "What you will learn in our Three-day Lip Blush Course.",
        whatWouldYouLearn: [
          "Lip mapping and design.",
          "Sanitation.",
          "Color theory and Pigment knowledge.",
          "Touch-up procedures.",
          "Contraindications.",
          "Needle/Cartridge knowledge.",
          "How to use Tattoo machine.",
          "Hands on technique training on a live model.",
          "How to start your business(Legalities).",
          "How to review your client consent forms.",
          "How to price your services.",
          "How to take photos and how to edit.",
          "Marketing 101!",
        ],
        whatIsIncludedTitle: "What is included in our Course?",
        whatIsIncluded: [
          "Unique handbook/manual.",
          "Supply list.",
          "Lip mapping kit.",
          "Pre-op and post-op care instruction cards(digital).",
          "Discounts for future courses.",
        ],
        howToGetStartedTitle: "How to get Registered: ",
        howToGetStarted: [
          {
            steps: [
              "Follow the next steps:",
              "Click 'REGISTER NOW' to register directly through our site. ",
              "Pay your $400 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl: "https://SolylunabeautiqueAustin.as.me/Lipblushcourse",
          },
          {
            steps: [
              "Follow the Next steps:",
              "Send us a message to get current training dates or to book a 1:1 private class.",
              "Pay your $400 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl: "https://SolylunabeautiqueAustin.as.me/Lipblushcourse",
          },
        ],
        classRequirementsTitle: "Class Prerequisites",
        classRequirements: [
          "Take BBP(Blood Bourne Pathogens) course before class starts. This course is taken online and is usually $15-$30.",
        ],
      },
      price: "$2200.00 + USD",
      packageOptions: [
        {
          package: "Professional Starter Package:",
          packageDetails: [],
          kitTitle: "Kit Includes:",
          kitIncludes: [
            "Tattoo Machine.",
            "Pigments.",
            "Cartridges/Needles.",
            "Basic Mapping Instruments.",
          ],
          price: "$2200.00 + USD",
        },
      ],
    },
    // PMU Three-day Powder Brows course
    {
      courseId: "threeDayPowderBrowsCourse",
      courseName: "Three-day Powder Brows Course",
      image: PowderBrows,
      alt: "Three-day Powder Brows Course Image",
      smallDescription:
        " Become a Permanent Makeup artist and help others get the brows they wish for.  Powder brows is the most popular service in the permanent makeup industry. Earn your certification within three days and work on your first model under our direct supervision from start to finish.",
      bookingUrl: " https://SolylunabeautiqueAustin.as.me/Powderbrowcourse ",
      academyDetails: {
        longDescription:
          "Powder Brows is a Permanent makeup technique that uses a machine to create powdery like pixels to create a makeup illusion. Clients can choose between light/Nautural or a bold makeup look. Learn how to customize your clients brow shape, color and technique to provide them with the brows they dream of.",
        whatWouldYouLearnTitle:
          "What you will learn in our Three-day Powder Brows Course.",
        whatWouldYouLearn: [
          "Brow mapping and design.",
          "Sanitation.",
          "Color theory and Pigment knowledge.",
          "Touch-up procedures.",
          "Contraindications.",
          "Needle/Cartridge knowledge.",
          "How to use Tattoo machine.",
          "Hands on technique training on a live model.",
          "How to start your business(Legalities).",
          "How to review your client consent forms.",
          "How to price your services.",
          "How to take photos and how to edit.",
          "Marketing 101!",
        ],
        whatIsIncludedTitle: "What is included in our Course?",
        whatIsIncluded: [
          "Unique handbook/manual.",
          "Supply list.",
          "Brow mapping kit.",
          "Pre-op and post-op care instruction cards(digital).",
          "Discounts for future courses.",
        ],
        howToGetStartedTitle: "How to get Registered: ",
        howToGetStarted: [
          {
            steps: [
              "Follow the next steps:",
              "Click 'REGISTER NOW' to register directly through our site. ",
              "Pay your $400 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl: "https://SolylunabeautiqueAustin.as.me/Powderbrowcourse",
          },
          {
            steps: [
              "Follow the next steps:",
              "Send us a message to get current training dates or to book a 1:1 private class.",
              "Pay your $400 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl: "https://SolylunabeautiqueAustin.as.me/Powderbrowcourse",
          },
        ],
        classRequirementsTitle: "Class Prerequisites",
        classRequirements: [
          "Take BBP(Blood Bourne Pathogens) course before class starts. This course is taken online and is usually $15-$30.",
        ],
      },
      price: "$2200.00 + USD",
      packageOptions: [
        {
          package: "Professional Starter Package:",
          packageDetails: "",
          kitTitle: "Kit Includes",
          kitIncludes: [
            "Tattoo Machine.",
            "Pigments.",
            "Cartridges/Needles.",
            "Basic Mapping Instruments.",
          ],
          price: "$2200",
        },
      ],
    },
  ],

  //   ===========================================================================================================
  //   ===========================================================================================================

  // Body Contouring SECTION
  "Body Contouring": [
    //Master body contouring Course
    {
      courseId: "masterBodyContouringCourse",
      courseName: "Master Body Contouring Course",
      image: AcademyBodyContouring,
      alt: "Master body contouring Course Image",
      smallDescription:
        "Learn the most popular non-invasive Body Contouring procedures.",
      bookingUrl:
        "https://SolylunabeautiqueAustin.as.me/MasterBodyContouringTraining",
      academyDetails: {
        longDescription:
          "Our Master Body Contouring Course will give you a 360 insight of all body contouring procedures. The Two most common techniques used are Wood therapy and machine Contouring. You will be able to help your clients to tone and contour their body. Help them lose inches, diminish the appearance of cellulite and more! All of these procedures are safe and require no down time or surgery.",
        whatWouldYouLearnTitle:
          "What you will learn in our Master Body Contouring Course:",
        whatWouldYouLearn: [
          "Cavitation.",
          "Radio Frequency.",
          "Vacuum Therapy.",
          "Laser Lipo.",
          "Wood Therapy.",
          "EMS.",
          "Cosmetic Post-op procedures with limitations.",
          "Lymphatic drainage theory.",
          "Non-Invasive BBL.",
          "Cellulite treatments.",
          "Hands on technique training on a live model.",
          "Sculpting safety.",
          "How to start your business(Legalities).",
          "How to review your client consent forms.",
          "How to price your services .",
          "How to be precise with measurements and photos.",
          "Marketing 101!",
        ],
        whatIsIncludedTitle:
          "What is included in our Master Body Contouring Course?",
        whatIsIncluded: [
          "Unique handbook/manual.",
          "Supply list.",
          "Measurements kit.",
          "Consent forms.",
          "Measurement forms.",
          "Pre op and post op care instruction cards(digital).",
          "Discounts for future courses.",
        ],
        howToGetStartedTitle: "How to get Registered: ",
        howToGetStarted: [
          {
            steps: [
              "Follow the next steps:",
              "Click 'REGISTER NOW' to register directly through our site.",
              "Pay your $300 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/MasterBodyContouringTraining",
          },
          {
            steps: [
              "Follow the next steps:",
              "Send us a message to get current training dates or to book a 1:1 private class.",
              "Pay your $300 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/MasterBodyContouringTraining",
          },
        ],
        classRequirementsTitle: "No previous experience or License required",
        classRequirements: [""],
      },
      price: "DIFFERENT PACKAGES AVAILABLE",
      packageOptions: [
        {
          package: "Certification Only",
          packageDetails: ["One day course. ", "Certificate. "],
          kitTitle: "",
          kitIncludes: [],
          price: "$1100.00 + USD.",
        },
        {
          package: "Standard Package",
          packageDetails: ["One day course. ", "Certificate. "],
          kitTitle: "Kit Includes: ",
          kitIncludes: [
            "6-1 Machine.",
            "Colombian Wood Therapy Kit.",
            "SyL Student Swag.",
            "Measuring Tape.",
            "Oil",
          ],
          price: "$1600.00 + USD.",
        },
        {
          package: "Luxury Package",
          packageDetails: ["One day course. ", "Certificate. "],
          kitTitle: "Kit Includes:",
          kitIncludes: [
            "6-1 Machine.",
            "Colombian Wood Therapy Kit.",
            "2 in 1 EMS/Vacuum machine.",
            "SyL Student Swag",
            "Measuring Tape",
            "Oil",
          ],
          price: "$2000.00 + USD.",
        },
      ],
    },
    //Wood Therapy Sculpting Course
    {
      courseId: "woodTherapySculptingCourse",
      courseName: "Wood Therapy Sculpting Course",
      image: WoodTherapy,
      alt: "Wood Therapy Sculpting Course Image",
      smallDescription:
        "Learn the most holistic way to tighten and tone using Colombian Wood Therapy tools.",
      bookingUrl:
        " https://SolylunabeautiqueAustin.as.me/Woodtherapycertification",
      academyDetails: {
        longDescription:
          "Wood Therapy Learn the most holistic way to tighten and tone using Colombian Wood Therapy tools. Help your clients lose inches, diminish cellulite and flatten the tummy. ",
        whatWouldYouLearnTitle:
          "What you will learn in our Wood Therapy Sculpting Course.",
        whatWouldYouLearn: [
          "Body sculpting through Wood therapy.",
          "How to use wood therapy tools.",
          "Lymphatic drainage theory.",
          "Hands on technique training on a live model.",
          "Sculpting safety.",
          "How to start your business(Legalities).",
          "How to review your client consent forms.",
          "How to price your services .",
          "How to be precise with measurements and photos.",
          "Marketing 101!",
        ],
        whatIsIncludedTitle: "What is included in our Course?",
        whatIsIncluded: [
          "Unique handbook/manual.",
          "Supply list.",
          "Measurements kit.",
          "Consent forms.",
          "Measurement forms.",
          "Pre op and post op care instruction cards(digital).",
          "Discounts for future courses.",
        ],
        howToGetStartedTitle: "How to get Registered: ",
        howToGetStarted: [
          {
            steps: [
              "Follow the next steps:",
              "Click 'REGISTER NOW' to register directly through our site. ",
              "Pay your $100 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Woodtherapycertification",
          },
          {
            steps: [
              "Follow the next steps:",
              "Send us a message to get current training dates or to book a 1:1 private class.",
              "Pay your $100 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Woodtherapycertification",
          },
        ],
        classRequirementsTitle: "Class Prerequisites",
        classRequirements: [
          "No Previous experience necessary, No license required",
        ],
      },
      price: "DIFFERENT PACKAGES AVAILABLE",
      packageOptions: [
        {
          package: "Certification Only",
          packageDetails: ["One day course. ", "Certificate. "],
          kitTitle: "",
          kitIncludes: [],
          price: "$549.00 + USD",
        },
        {
          package: "Starter Package.",
          packageDetails: [
            "One day course.",
            "Colombian Wood Therapy Kit.",
            "Certificate. ",
          ],
          kitTitle: "Kit Includes",
          kitIncludes: [
            "Five piece Colombian wood therapy set: Mushroom cup, swedish cup, Flat curved board, cubed roller and ridged roller.",
          ],
          price: "$649.00 + USD",
        },
      ],
    },
    //Ice Body Sculpting Course
    {
      courseId: "iceBodySculptingCourse",
      courseName: "Ice Body Sculpting Course",
      image: IceTherapySculpting,
      alt: "Ice Body Sculpting Course Image",
      smallDescription:
        "Learn one of the best Non-Invasive techniques to contour the body and lose inches, the cool way!.",
      bookingUrl:
        "https://SolylunabeautiqueAustin.as.me/Icetherapycertification",
      academyDetails: {
        longDescription:
          "Ice Body Sculpting Course is a Body Contouring technique that uses frozen clay and herbs to burn calories, tone and tighten through thermogenesis. Help your client lose fat, burn calories and more!",
        whatWouldYouLearnTitle:
          "What you will learn in our Ice Body Sculpting Course:",
        whatWouldYouLearn: [
          "Body sculpting through thermogenesis.",
          "How to use metal therapy tools.",
          "Hands on technique training on a live model.",
          "Sculpting safety.",
          "How to start your business(Legalities).",
          "How to review your client consent forms.",
          "How to price your services .",
          "How to be precise with measurements and photos.",
          "Marketing 101!",
        ],
        whatIsIncludedTitle: "What is included in our Course?",
        whatIsIncluded: [
          "Unique handbook/manual.",
          "Supply list.",
          "Measurements kit.",
          "Consent forms.",
          "Measurement forms.",
          "Pre op and post op care instruction cards(digital).",
          "Discounts for future courses.",
        ],
        howToGetStartedTitle: "How to get Registered: ",
        howToGetStarted: [
          {
            steps: [
              "Follow the next steps:",
              "Click 'REGISTER NOW' to register directly through our site.",
              "Pay your $100 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Icetherapycertification",
          },
          {
            steps: [
              "Follow the next steps:",
              "Send us a message to get current training dates or to book a 1:1 private class.",
              "Pay your $100 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Icetherapycertification",
          },
        ],
        classRequirementsTitle: "Class Prerequisites",
        classRequirements: ["No experience necessary, No license required"],
      },
      price: "$549.00 + USD.",
      packageOptions: [
        {
          package: "",
          packageDetails: [""],
          kitTitle: "",
          kitIncludes: [],
          price: "",
        },
      ],
    },
    //Yeso Therapy Course
    {
      courseId: "yesoTherapyCourse",
      courseName: "Yeso Therapy Course",
      image: YesoTherapy,
      alt: "Yeso Therapy Course Image",
      smallDescription:
        "Expand your Body Contouring knowledge and add this great service to your menu.",
      bookingUrl:
        "https://SolylunabeautiqueAustin.as.me/Yesotherapycertification",
      academyDetails: {
        longDescription:
          "Yeso Therapy Course is a service used to help your clients lose inches in a quickly manner. Great as an add-on to your body contouring services.",
        whatWouldYouLearnTitle:
          "What you will learn in our Yeso Therapy Course:",
        whatWouldYouLearn: [
          "Body sculpting through Yeso therapy wraps.",
          "How Yeso works and different application techniques.",
          "Hands on technique training on a live model.",
          "How to start your business(Legalities).",
          "How to review your client consent forms.",
          "How to price your services.",
          "How to be precise with measurements and photos.",
          "Marketing 101!",
        ],
        whatIsIncludedTitle: "What is included in our Course?",
        whatIsIncluded: [
          "Unique handbook/manual.",
          "Supply list.",
          "Measurements kit.",
          "Consent forms.",
          "Measurement forms.",
          "Pre op and post op care instruction cards(digital).",
          "Discounts for future courses.",
        ],
        howToGetStartedTitle: "How to get Registered: ",
        howToGetStarted: [
          {
            steps: [
              "Follow the next steps:",
              "Click 'REGISTER NOW' to register directly through our site.",
              "Pay your $100 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Yesotherapycertification",
          },
          {
            steps: [
              "Follow the next steps:",
              "Send us a message to get current training dates or to book a 1:1 private class.",
              "Pay your $100 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Yesotherapycertification",
          },
        ],
        classRequirementsTitle: "Class Prerequisites",
        classRequirements: [
          "Body contouring Certification recommended but not required.",
        ],
      },
      price: "$299.00 + USD.",
      packageOptions: [
        {
          package: "",
          packageDetails: [""],
          kitTitle: "",
          kitIncludes: [],
          price: "",
        },
      ],
    },
  ],

  //   ===========================================================================================================
  //   ===========================================================================================================

  // Aesthetics SECTION
  Aesthetics: [
    //Dermaplaning + Jelly Mask Application Course
    {
      courseId: "dermaplaningJellyMaskCourse",
      courseName: "Dermaplaning + Jelly Mask Application Course",
      image: AcademyDermaplaning,
      alt: "Dermaplaning + Jelly Mask Application Course Image",
      smallDescription:
        "Learn about mechanical exfoliation, Give your clients glowy glass skin and learn how to use the very popular Jelly masks.",
      bookingUrl:
        "https://SolylunabeautiqueAustin.as.me/Dermaplanecertification",
      academyDetails: {
        longDescription:
          "Dermaplaning + Jelly Mask Application Course Students will learn how to perform a full Dermaplaning routine. They will also learn what Dermaplaning is, how it works,and the contraindications. Dermaplaning is a way of exfoliation to remove dead skin cells and vellus hair/peach fuzz from the face.",
        whatWouldYouLearnTitle:
          "What you will learn in our Dermaplaning + Jelly Mask Application Course:",
        whatWouldYouLearn: [
          "Skin Anatomy.",
          "Hands on technique training on a live model.",
          "Dermatome/scalpel safety.",
          "How to start your business(Legalities).",
          "How to review your client consent forms.",
          "How to price your services .",
          "Marketing 101!",
        ],
        whatIsIncludedTitle: "What is included in our Course?",
        whatIsIncluded: [
          "Unique handbook/manual.",
          "Supply list.",
          "Consent forms.",
          "Discounts for future courses.",
        ],
        howToGetStartedTitle: "How to get Registered: ",
        howToGetStarted: [
          {
            steps: [
              "Follow the next steps:",
              "Click 'REGISTER NOW' to register directly through our site ",
              "Pay your $100 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Dermaplanecertification",
          },
          {
            steps: [
              "Follow the next steps:",
              "Send us a message to get current training dates or to book a 1:1 private class.",
              "Pay your $100 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Dermaplanecertification",
          },
        ],
        classRequirementsTitle: "Class Prerequisites",
        classRequirements: [
          "No previous experience necessary, No license required.",
        ],
      },
      price: "DIFFERENT PACKAGES AVAILABLE",
      packageOptions: [
        {
          package: "Certification Only",
          packageDetails: ["One day course.", "Certificate"],
          kitTitle: "Kit Includes",
          kitIncludes: [],
          price: "$349.00 + USD",
        },
        {
          package: "Starter Package",
          packageDetails: [
            "One day course.",
            "Includes Dermaplaning kit.",
            "Your choice of Jelly mask for 10+ applications",
            "Certificate.",
          ],
          kitTitle: "Kit Includes",
          kitIncludes: [],
          price: "$429.00 + USD",
        },
      ],
    },
  ],
  //   ===========================================================================================================
  //   ===========================================================================================================

  // Teeth Whitening SECTION
  "Teeth Whitening": [
    //Teeth Whitening & Tooth Gem Application Course
    {
      courseId: "teethWhiteningToothGemCourse",
      courseName: "Teeth Whitening & Tooth Gem Application Course",
      image: TeethWhitening,
      alt: "Teeth Whitening & Tooth Gem Application Course Image",
      smallDescription:
        "Teeth whitening is a very profitable cosmetic service,Learn Everything you need to become a Teeth Whitening specialist. Training provided by a Dental professional of 12+ years.",
      bookingUrl:
        " https://SolylunabeautiqueAustin.as.me/Teethwhiteningtraining",
      academyDetails: {
        longDescription:
          "Our Teeth Whitening & Tooth Gem Application course will prepare you to provide great brightening results for your clients. Help your clients whiten 1-8 shades instantly and safely.",
        whatWouldYouLearnTitle:
          "What you will learn in our Teeth Whitening & Tooth Gem Application Course:",
        whatWouldYouLearn: [
          "Dental anatomy.",
          "Identifying dental materials and what they’re used for.",
          "Hands on technique training on a live model.",
          "Product safety.",
          "How to start your business(Legalities).",
          "How to review your client consent forms.",
          "How to price your services .",
          "How to be precise with measurements and photos.",
          "Tooth gem application.",
          "Marketing 101!",
        ],
        whatIsIncludedTitle: "What is included in our Course?",
        whatIsIncluded: [
          "Unique handbook/manual.",
          "Supply list.",
          "Consent forms.",
          "Pre-op and post-op care instruction cards(digital).",
          "Discounts for future courses.",
        ],
        howToGetStartedTitle: "How to get Registered: ",
        howToGetStarted: [
          {
            steps: [
              "Follow the next steps:",
              "Click 'REGISTER NOW' to register directly through our site ",
              "Pay your $300 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Teethwhiteningtraining",
          },
          {
            steps: [
              "Follow the Next steps:",
              "Send us a message to get current training dates or to book a 1:1 private class.",
              "Pay your $300 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Teethwhiteningtraining",
          },
        ],
        classRequirementsTitle: "Class Prerequisites",
        classRequirements: [
          "Take BBP(Blood Bourne Pathogens) course before class.",
        ],
      },
      price: "DIFFERENT PACKAGES AVAILABLE",
      packageOptions: [
        {
          package: "Certification Only",
          packageDetails: ["One day course.", "Certificate"],
          kitTitle: "",
          kitIncludes: [],
          price: "$799.00 + USD",
        },
        {
          package: "Starter Package",
          packageDetails: [
            "One day course.",
            "Professional Teeth Whitening kit",
            "Certificate.",
          ],

          kitTitle: "Kit Includes",
          kitIncludes: [
            "Materials to service 10+ clients",
            "Dental bibs, Cotton rolls, Gauze and other disposable supplies.",
            "Dental Tooth Shade Guide",
            "Gingival barrier gel.",
            "Dental whitening gel.",
            "Desensitizing gel.",
            "10 cheek retractors.",
            "Dental curing light.",
            "Professional Red/Blue light.",
            "Mobile Carry Case",
          ],

          price: "$1400.00 + USD",
        },
      ],
    },
    //Tooth Gem Application Course
    {
      courseId: "toothGemCourse",
      courseName: "Tooth Gem Application Course",
      image: ToothGem,
      alt: "Tooth Gem Application Course Image",
      smallDescription:
        "Learn how to safely place Tooth Gems to decorate your clients smiles",
      bookingUrl:
        "https://SolylunabeautiqueAustin.as.me/Toothgemapplicationcourse",
      academyDetails: {
        longDescription:
          "Learn the art of tooth gem application. Learn from a Dental professional, safely and correctly apply tooth gems",
        whatWouldYouLearnTitle:
          "What you will learn in our Tooth Gem Application Course:",
        whatWouldYouLearn: [
          "Dental anatomy.",
          "Identifying dental materials and what they’re used for.",
          "Hands on technique.",
          "Product safety.",
          "How to start your business(Legalities).",
          "How to review your client consent forms.",
          "How to price your services .",
          "How to be precise with measurements and photos.",
          "Limitations",
          "Marketing 101!",
        ],
        whatIsIncludedTitle: "What is included in our Course?",
        whatIsIncluded: [
          "Unique handbook/manual.",
          "Supply list.",
          "Consent forms.",
          "Pre-op and post-op care instruction cards(digital).",
          "Discounts for future courses.",
        ],
        howToGetStartedTitle: "How to get Registered: ",
        howToGetStarted: [
          {
            steps: [
              "Follow the next steps:",
              "Click 'REGISTER NOW' to register directly through our site ",
              "Pay your $300 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Toothgemapplicationcourse",
          },
          {
            steps: [
              "Follow the next steps:",
              "Send us a message to get current training dates or to book a 1:1 private class.",
              "Pay your $300 deposit.",
              "Receive registration confirmation through email.",
              "Pay remaining balance 24 hours before class starts.",
            ],
            actionUrl:
              "https://SolylunabeautiqueAustin.as.me/Toothgemapplicationcourse",
          },
        ],
        classRequirementsTitle: "Class Prerequisites",
        classRequirements: [
          "Take BBP(Blood Bourne Pathogens) course before class starts. This course is taken online and is usually $15-$30",
        ],
      },
      price: "$299.00 + USD",
      packageOptions: [
        {
          package: "",
          packageDetails: [""],
          kitTitle: "",
          kitIncludes: [],
          price: "",
        },
      ],
    },
  ],
};
