// Dependencies
import React, { useEffect } from "react";
import styled from "styled-components/macro";

function PrivacyPolicyScreen() {
  useEffect(() => {
    document.title = "Sol y Luna Beautique : Privacy Policy";
  });
  // termly script function for the iframe to work
  const d = document;
  const s = "script";
  const id = "termly-jssdk";
  var js,
    tjs = d.getElementsByTagName(s)[0];

  js = d.createElement(s);
  js.id = id;
  js.src = "https://app.termly.io/embed-policy.min.js";
  tjs.parentNode.insertBefore(js, tjs);
  return (
    // privacy n policy main div with termly iframe
    <PrivacyPolicyScreenMainDiv
      name="termly-embed"
      data-id="eaf3a843-6685-4c14-8423-ee008c2c3e59"
      data-type="iframe"
    ></PrivacyPolicyScreenMainDiv>
  );
}

export default PrivacyPolicyScreen;
// privacy and policy styling div
const PrivacyPolicyScreenMainDiv = styled.div`
  background-color: rgba(156, 128, 104, 0.9);
  min-height: 100vh;
  padding: 40px 40px 50px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
