import axios from "axios";
const access_token = process.env.REACT_APP_FB_CONVERSIONS_API_ACCESS_TOKEN;
const pixel_id = process.env.REACT_APP_FB_PIXEL_ID;

export const facebookConversion = async (
  eventID,
  eventName,
  customData = {}
) => {
  const URL = `https://graph.facebook.com/v15.0/${pixel_id}/events?access_token=${access_token}`;
  const date = new Date();
  const ip = await getIp();

  if (ip) {
    await axios.post(URL, {
      data: [
        {
          event_id: eventID,
          event_name: eventName,
          event_time: Math.floor(date.getTime() / 1000),
          action_source: "website",
          event_source_url: window.location.href,

          user_data: {
            client_ip_address: ip,
            client_user_agent: window.navigator.userAgent,
            ph: null,
            //   em: [
            //     "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068",
            //   ],
          },
          custom_data: customData,
        },
      ],
      // test_event_code: "TEST47512",
    });
  }
};

const getIp = async () => {
  const ipData = await axios.get("https://www.cloudflare.com/cdn-cgi/trace");
  const reIpv6 = /ip=([a-zA-z0-9:.]*)/gm;
  const ip = reIpv6.exec(ipData.data)[1];
  if (!ip) {
    return;
  }
  return ip;
};
