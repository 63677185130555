//Dependecies
import React, { useEffect } from "react";

import styled from "styled-components/macro";
import { Container, Col, Row } from "react-bootstrap";
//Importing css
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Import icons from FontAwesome Solid
import {
  faFileShield,
  faMoneyBillTransfer,
  faClock,
  faCalendarXmark,
  faLayerGroup,
  faMoneyCheckDollar,
} from "@fortawesome/free-solid-svg-icons";

function PoliciesScreen() {
  useEffect(() => {
    document.title = "Sol y Luna Beautique : Policies Screen";
  });
  return (
    //Main Policies screen div
    <MainPoliciesScreenDiv>
      {/* policies title main div  */}
      <main id="main">
        <div id="SeparadorInicial"></div>
        {/* !-- ======= Start SelfCareSession Section ======= -- */}
        <section id="policies" className="policies">
          {/* bs-containe */}
          <Container>
            {/* page title Policies */}
            <h3 className="policies" style={{ textAlign: "center" }}>
              Policies
            </h3>
            {/* bs-row that holds all the policies divs  */}
            <Row>
              {/* bs-column cancelation  */}
              <Col md="12">
                <div className="icon-box icon-box-policies">
                  {/* calendar icon */}
                  <FontAwesomeIcon className="icons" icon={faCalendarXmark} />
                  <h4>Cancelation Policies</h4>
                  <p>
                    Please notify us if you cannot attend your appointment
                    <i className="strong"> at least 48 hours prior.</i> Failure
                    to give adequate notice, will result in loss of deposit.
                  </p>
                  <p>
                    All same day cancellations will result in a full charge of
                    the service fee.
                  </p>
                </div>
              </Col>
              {/* bs-column refund policies */}
              <Col md="12">
                <div className="icon-box icon-box-policies">
                  {/* money icon  */}
                  <FontAwesomeIcon
                    className="icons"
                    icon={faMoneyBillTransfer}
                  />
                  <h4>Refund Policies</h4>
                  <p>
                    We do not offer refunds on any{" "}
                    <i className="strong"> services or trainings.</i>
                  </p>
                  <p>
                    When you book an appointment, You are paying for our time,
                    service, product and expenses to provide you with a service.
                  </p>
                </div>
              </Col>
              {/* bs-column late policies */}
              <Col md="12">
                <div className="icon-box icon-box-policies">
                  {/* clock icon  */}
                  <FontAwesomeIcon className="icons" icon={faClock} />
                  <h4>Late Policies</h4>
                  <p>
                    Arrival after <i className="strong">15 minutes</i> will be
                    subject to a rescheduled appointment.
                  </p>
                </div>
              </Col>
              {/* bs-column bundle policies  */}
              <Col md="12">
                <div className="icon-box icon-box-policies">
                  {/* bundle icon */}
                  <FontAwesomeIcon className="icons" icon={faLayerGroup} />
                  <h4>Bundle Policies</h4>
                  <p>
                    When booking bundles, you must schedule all your sessions.
                    You use them or lose them
                  </p>
                  <p>
                    <i className="strong">
                      {" "}
                      No REFUNDS given on sessions that are not used/Scheduled.
                    </i>
                  </p>
                </div>
              </Col>
              {/* bs-column facility safety rules  */}
              <Col md="12">
                <div className="icon-box icon-box-policies">
                  {/* shield icon  */}
                  <FontAwesomeIcon className="icons" icon={faFileShield} />
                  <h4>Facility safety Policies</h4>
                  <p>
                    <i className="strong">
                      We love the babies and the fur babies,
                    </i>{" "}
                    to better protect them and the integrity of our services, we
                    cannot allow extra guests.
                  </p>
                </div>
              </Col>
              {/* bs-column Training deposits  */}
              <Col md="12">
                <div className="icon-box icon-box-policies">
                  {/* deposit icon */}
                  <FontAwesomeIcon
                    className="icons"
                    icon={faMoneyCheckDollar}
                  />
                  <h4>Training Deposits</h4>
                  <p>
                    <i className="strong">
                      Deposits for trainings are NON-REFUNDABLE and
                      NON-TRANSFERABLE.
                    </i>
                  </p>
                  <p>
                    <i className="strong">
                      If you cannot attend class, we require a 48 hour notice.
                    </i>
                  </p>
                  <p>
                    <i className="strong">
                      Failure to provide adequate notice will result in loss of
                      deposit.
                    </i>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </MainPoliciesScreenDiv>
  );
}

export default PoliciesScreen;

//Main Policies screen div
const MainPoliciesScreenDiv = styled.div`
  margin-bottom: 75px;
`;
