// dependencies
import * as React from "react";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";
// cherry logo svg
import { ReactComponent as CherryFinancingLogo } from "../../assets/images/paymentPlansCherry/Logo-Cherry.svg";
//Import icons from FontAwesome Brands
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components/macro";
import ReactPixel from "react-facebook-pixel";

// importing the logo for the footer
import LogoSYL from "../../assets/images/logo/SOLyLuna-300x300px-01.png";
import { facebookConversion } from "../../tools/facebookConversion";

function Footer() {
  const year = new Date().getFullYear();
  const API = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    // main footer container from bs
    <MainFooterContainer>
      {/* Social media BS-Row */}
      <SocialMediaRow lg={12}>
        {/* social media title div */}
        <Title>Connect with Us:</Title>
        {/* social media user name h1 */}
        <SocialUserName>@ solylunabeautique</SocialUserName>
        {/* social link icon main div inside the social media BS-Row */}
        <SocialLinks>
          {/* Facebook icon with url */}
          <FacebookIcon
            href="https://www.facebook.com/SolyLunaBeautique"
            target="_blank"
          >
            <FontAwesomeIcon label="Facebook" icon={faFacebook} />
          </FacebookIcon>
          {/* Instagram icon with url */}
          <InstagramIcon
            href="https://www.instagram.com/solylunabeautique/?hl=en"
            target="_blank"
          >
            <FontAwesomeIcon label="Instagram" icon={faInstagram} />
          </InstagramIcon>
          {/* TikTok icon with url */}
          <TikTokIcon
            href="https://www.tiktok.com/@solylunabeautique"
            target="_blank"
          >
            <FontAwesomeIcon label="TikTok" icon={faTiktok} />
          </TikTokIcon>
          {/* Google icon with url */}
          <GoogleIcon
            href="https://g.page/sol-y-luna-beautique?share"
            target="_blank"
          >
            <FontAwesomeIcon label="Google" icon={faGoogle} />
          </GoogleIcon>
        </SocialLinks>
      </SocialMediaRow>
      {/* colums section main BS-Row */}
      <ColumnsSectionRow className="justify-content-center">
        {/* first section div in the footer with the SYL Logo   */}
        <FirstSectionLogoMainDiv
          href="/"
          className="col-lg-3 col-md-6 col-sm-12 col-xs-12 text-center"
        >
          {/* footerLogo */}
          <SYLlogo src={LogoSYL} alt="syl-logo" />
        </FirstSectionLogoMainDiv>
        {/* Second div section in the footer with the list of policy links  */}
        <SecondSectionPoliciesMainDiv className="col-lg-2 col-md-6 col-sm-12 col-xs-12 text-center">
          <ul className="list-unstyled">
            <li>
              <TextLink href="/legal/termsandconditions">
                Terms and Conditions
              </TextLink>
            </li>
            <li>
              <TextLink href="/legal/:privacypolicy">Privacy Policy</TextLink>
            </li>
            <li>
              <TextLink href="/legal/shippingpolicy">Shipping Policy</TextLink>
            </li>
          </ul>
        </SecondSectionPoliciesMainDiv>

        {/* Third div section in the footer with the list of policy links   */}
        <ThirdSectionPoliciesMainDiv className="col-lg-2 col-md-6 col-sm-12 col-xs-12 text-center">
          <ul className="list-unstyled">
            <li>
              <TextLink href="/academy/permanentMakeup">Academy</TextLink>
            </li>
            <li>
              <TextLink href="/legal/disclaimer">Disclaimer</TextLink>
            </li>
            <li>
              <TextLink href="/legal/returnpolicy">Return Policy</TextLink>
            </li>
          </ul>
        </ThirdSectionPoliciesMainDiv>
        {/* Financing Colum with Cherry Logo  */}
        <FinancingColumnDiv className="col-lg-2 col-md-6 col-sm-12 col-xs-12 text-center">
          <ul className="list-unstyled">
            <FinancingTitle>Financing</FinancingTitle>
            <li>
              <CherryLogoDiv>
                <CherryLogo
                  onClick={() => {
                    window.open(
                      "https://pay.withcherry.com/solylunabeautique?utm_source=practice&m=9182"
                    );
                    // FB_Pixel Lead Pixel track event cherry financing in the footer
                    ReactPixel.track(`Lead`, {
                      id: "CherryFooterButton",
                      content_name: `CherryFinance-Footer`,
                      content_type: `CherryFinance-Footer`,
                    });
                    // FB convertion api cherry button lead
                    facebookConversion("Cherry-Footer-Button", `Lead`);
                    // GoogleAnalytics4 react GA$ ever for Service details
                    ReactGA.event({
                      name: "Cherry Financing",
                      category: "Payment-Plans",
                      action: "Payment-Plans-Cherry",
                      label: "Payment-Plans-Cherry-Footer", // optional
                    });
                  }}
                />
              </CherryLogoDiv>
            </li>
          </ul>
        </FinancingColumnDiv>
        {/* Fourth div section in the footer with the Google map  */}
        <FourthColumnMapsMainDiv className="col-lg-3 col-md-6 col-sm-12 col-xs-12 text-center">
          {/* iframe with the google map */}
          <StyledIframe
            loading="lazy"
            src={
              `https://www.google.com/maps/embed/v1/place?key=` +
              `${API}` +
              `&q=Sol+y+Luna+Beautique`
            }
          ></StyledIframe>
        </FourthColumnMapsMainDiv>
      </ColumnsSectionRow>
      {/* CopyRights BS-Row */}
      <CopyRightsRow className="justify-content-md-center">
        {/* copyRights div */}
        <CopyRights>
          ⓒ Sol Y Luna Beautique. All rights reserved. {year}
        </CopyRights>
      </CopyRightsRow>
    </MainFooterContainer>
  );
}
// main footer BS-Container
const MainFooterContainer = styled.div`
  background-color: #f4c5cc;
  min-width: 100%;
  height: auto;
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
`;
// styling the socialMedia BS-Row
const SocialMediaRow = styled.div`
  min-width: 100%;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;
// Div that holds the social media icons
const SocialLinks = styled.div`
  flex-wrap: wrap;
`;
// connect with us in the social medial div
const Title = styled.div`
  /* padding: 2px; */
  font-size: 20px;
`;
// Social media user name h1
const SocialUserName = styled.h1`
  font-weight: 700;
  padding: 8px;
  font-size: 20px;
  color: black;
  margin: 4px;
`;
// FB Icon
const FacebookIcon = styled.a`
  font-size: 45px;
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
  color: #4968ad;
  border-radius: 32px;
  &:hover {
    transform: translate(-2px);
  }
  @media (max-width: 480px) {
    font-size: 2220px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 1201px) {
    font-size: 30px;
  }
`;
// IG Icon
const InstagramIcon = styled.a`
  font-size: 45px;
  margin: 0.1rem;
  transition: transform 250ms;
  display: inline-block;
  color: black;
  &:hover {
    transform: translate(-2px);
  }
  @media (max-width: 480px) {
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 1201px) {
    font-size: 30px;
    line-height: 1.4;
  }
`;
// TikTok Icon
const TikTokIcon = styled.a`
  font-size: 45px;
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
  color: #111111;

  filter: drop-shadow(3px 0px 0px #fd3e3e) drop-shadow(-2px -2px 0px #4de8f4);
  &:hover {
    transform: translate(-2px);
  }
  @media (max-width: 480px) {
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 1201px) {
    font-size: 30px;
  }
`;
// Google Icon
const GoogleIcon = styled.a`
  font-size: 45px;
  margin: 0.1rem;
  transition: transform 250ms;
  display: inline-block;
  color: white;
  &:hover {
    transform: translate(-2px);
  }
  @media (max-width: 480px) {
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 1201px) {
    font-size: 30px;
  }
`;
// colums sections Main BS-Row
const ColumnsSectionRow = styled(Row)`
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-wrap: wrap;
`;
// styling the first section div that hold the logo
const FirstSectionLogoMainDiv = styled.div`
  min-height: 200px;
  align-items: center;
`;
// logo styling
const SYLlogo = styled.img`
  width: 220px;
  /* height: 100px; */
`;
// Styling the second div section with the poilies links
const SecondSectionPoliciesMainDiv = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
// Styling the Third div section with the poilies links
const ThirdSectionPoliciesMainDiv = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
// individual links
const TextLink = styled.a`
  text-decoration: none;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 40px;
  &:hover {
    color: #ffb339;
    opacity: 1;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }
  &:active {
    color: #9c8068;
  }
`;
// fourth section div with the google maps iframe
const FourthColumnMapsMainDiv = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  padding: 5px 25px 5px 25px;
`;

//FinancingDiv
const FinancingColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
`;

// Styling iframe with the google maps
const StyledIframe = styled.iframe`
  min-width: 220px;
  height: 160px;
  border: 0;
  box-shadow: 5px 5px 5px;
`;
// copyRights Main BS-Row
const CopyRightsRow = styled(Row)`
  border-top: 1px solid white;
`;
// Copywrite div
const CopyRights = styled.div`
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  font-size: 15px;
`; // financing title
const FinancingTitle = styled.li`
  font-size: 18px;
  font-weight: 700;
  padding: 38px 0 20px 0;
  /* cursor: pointer; */
`;
// logo div styling
const CherryLogoDiv = styled.div`
  height: auto;
  cursor: pointer;
  & :hover {
    opacity: 0.4;
    fill: #ffb339 !important;
    stroke: #ffb339 !important;
  }
`;
// logo styling
const CherryLogo = styled(CherryFinancingLogo)`
  width: 10rem;
  height: auto;
  color: white !important;
  & :hover {
    opacity: 0.9;
    fill: #ffb339 !important;
    stroke: #ffb339 !important;
  }
`;

export default Footer;
