//Dependecies
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Container, Button, Col, Row, Image } from "react-bootstrap";

//Importing css
import "../../App.css";

// importing SYL Image
import AboutUsImg from "../../assets/images/contactUs/SYLfront.jpg";

function AboutScreen() {
  useEffect(() => {
    document.title = "Sol y Luna Beautique : About Us";
  });
  return (
    //Main div for the about SYL page
    <MainAboutScreenDiv>
      {/* About SYL bootstrap Main */}
      <main id="main">
        {/* separator div for aboutScreen */}
        <div id="SeparadorInicialAboutUsScreen"></div>
        {/* !-- ======= About Section ======= -- */}
        <section id="about" className="section about section-bg">
          <Container className="container">
            {/* About us title  */}
            <h3 id="aboutus" style={{ textAlign: "center" }}>
              About Us
            </h3>
            <Row className="row content">
              {/* BS-Col with the about us SYL IMG */}
              <Col lg="6" md="12" xs="12" className="text-center col">
                <Image fluid="true" rounded="true" src={AboutUsImg} />
              </Col>
              {/* BS-Col with the about SYL BIO  */}
              <Col lg="6" md="12" xs="12" className="text-justify col">
                {/* SYL BIO TITLE */}
                <h3 id="aboutSYLpageTitle">Sol y Luna Beautique</h3>
                {/* TODO: Revise SYL Bio */}
                <p>
                  Sol y Luna was created to provide a comfortable and safe space
                  for all. We aim to offer Our Self Care services as a tool for
                  you to enhance your beauty and to assist in making you feel at
                  your best.
                </p>
                <p>
                  What sets us aside is our full transparency, We will not sell
                  any unnecessary upgrades if we don't truly think you will
                  benefit from it. Your trust and well being is our priority.
                </p>

                {/* Div with the book button inside the SYL BIO  */}
                <div className="text-center">
                  <Button
                    id="BookNow"
                    onClick={() =>
                      window.open(
                        "https://solylunabeautiqueaustin.as.me/schedule.php",
                        "_blank"
                      )
                    }
                  >
                    Book Now
                  </Button>
                </div>
              </Col>
            </Row>
            {/* bottom txt at the end of the bio section  */}
            <h4
              className="text-center"
              style={{
                color: "#FFB339",
                paddingTop: ".5em",
                paddingBottom: "1em",
                margin: "2rem",
                fontWeight: "400",
                fontSize: "2.5rem",
                lineHeight: "3rem",
              }}
            >
              We thank you for your support and referrals!
            </h4>
          </Container>
        </section>
        {/* !-- ======= End About Section ======= -- */}
      </main>
    </MainAboutScreenDiv>
  );
}

export default AboutScreen;

//Main Policies screen div
const MainAboutScreenDiv = styled.div``;
