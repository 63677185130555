// dependencies
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Container, Button, Col, Row, Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import ReactPixel from "react-facebook-pixel";

// importing images for carouselData
import TaniaInjecting from "../../assets/images/homePage/carousel/taniaInjectingCarousel-1.jpg";
import PMUroom from "../../assets/images/homePage/carousel/SYLpmuRoomCarousel-2.jpg";
import TaniaTeaching from "../../assets/images/homePage/carousel/teachingCarousel-3.jpg";
import SYLMerch from "../../assets/images/homePage/carousel/sYlMerchCarousel-4.jpg";
import IceSculptingTools from "../../assets/images/homePage/carousel/iceSculptingToolCarousel-5.jpg";
import CherryPayment from "../../assets/images/homePage/carousel/cherryPayment-6.jpg";

//Importing Bio images
import SYLhomeBioImg from "../../assets/images/contactUs/SYLfront.jpg";
import SYLhomeAcademyBioImg from "../../assets/images/homePage/SYLBioAcademy.jpg";

//Importing css
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Import icons from FontAwesome Regular
import { faKissWinkHeart } from "@fortawesome/free-regular-svg-icons";

//Import icons from FontAwesome Solid
import {
  faSyringe,
  faChildReaching,
  faTeeth,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { facebookConversion } from "../../tools/facebookConversion";

function HomeScreen() {
  // react router
  const navigate = useNavigate();

  useEffect(() => {
    // facebookConversion("homeView", "PageView");
  }, []);

  return (
    // Main Home screen div component
    <MainHomeScreenDiv>
      {/* my carousel component  */}
      <section id="hero">
        <Carousel
          fade
          variant="dark"
          className="homePageCarouse"
          interval={3000}
          pause="hover"
          prevLabel="Previous"
          nextLabel="Next"
        >
          {/* Carousel first image  */}
          <Carousel.Item>
            <Image
              className="d-block w-100 "
              src={TaniaInjecting}
              alt="Botox Injection Image"
            />
          </Carousel.Item>
          {/* Carousel second image  */}
          <Carousel.Item>
            <Image
              className="d-block w-100 "
              src={CherryPayment}
              alt="Payment Plans"
            />
          </Carousel.Item>
          {/* Carousel third image  */}
          <Carousel.Item>
            <Image
              className="d-block w-100 "
              src={PMUroom}
              alt="PMU Room at SYL"
            />
          </Carousel.Item>
          {/* Carousel fourth image  */}
          <Carousel.Item>
            <Image
              className="d-block w-100"
              src={TaniaTeaching}
              alt="Tania Teaching Class"
            />
          </Carousel.Item>
          {/* Carousel fifth image  */}
          <Carousel.Item>
            <Image
              className="d-block w-100"
              src={SYLMerch}
              alt="Sol Y Luna Merch"
            />
          </Carousel.Item>
          {/* Carousel sixth image  */}
          <Carousel.Item>
            <Image
              className="d-block w-100"
              src={IceSculptingTools}
              alt="Ice Scoulpting Tools Image"
            />
          </Carousel.Item>
        </Carousel>
      </section>
      {/* <Carousel /> */}

      {/* main section holds the bios and services section in the home screen  */}
      <main id="main">
        {/* !-- ======= Sol y Luna home Screen Bio ======= -- */}
        <section
          id="missionStatement"
          className="section missionStatement section-bg-mission-statement"
        >
          <Container className="container">
            <Row className="row content">
              {/* bio title  */}
              <h3>Sol y Luna Beautique</h3>
              {/* column for the SYL bio image  */}
              <Col lg="6" md="12" xs="12" className="text-center col">
                {/* syl bio img */}
                <Image fluid="true" rounded="true" src={SYLhomeBioImg} />
              </Col>
              {/* column with the SyL Bio */}
              <Col lg="6" md="12" xs="12" className="missionStatementText">
                <p>
                  Sol y Luna Beautique provides self care services to help bring
                  out the very best in you.
                </p>
                <p>
                  We strive to provide a unique, comfortable and positive
                  experience for each of our guests.
                </p>
                <p>Your trust and wellbeing is important to us.</p>
                {/* div holding the button to book */}
                <div className="text-center">
                  <Button
                    id="BookNow"
                    alt="Book Now Button"
                    onClick={() => {
                      // FB_Pixel AttToCart Pixel track event navBar bookNow button
                      ReactPixel.track(`AddToCart`, {
                        id: "HomeBioBookNowButton",
                        content_name: `BookNow-HomeBio`,
                        content_type: `BookNow-HomeBio`,
                      });
                      // FB convertion api AddToCArt for the home book now
                      facebookConversion("BookNow-HomeBio", `AddToCart`);
                      window.open(
                        "https://solylunabeautiqueaustin.as.me/schedule.php",
                        "_blank"
                      );
                    }}
                  >
                    Book Now
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* !-- ======= Sol y Luna home Screen Bio Section ======= -- */}
        {/* !-- ======= Start Sol y Luna Academy Bio Section ======= -- */}
        <section id="academy" className="section  academy section-bg-academy">
          <Container className="container">
            <Row className="row content">
              {/* bio title  */}
              <h3 className="academyHomeScreen">Sol y Luna Academy.</h3>
              {/* <Row className="content academy-reverse "> */}
              <AcademyContentDiv className="academy-reverse ">
                {/* column with the SyL Bio */}
                <Col lg="6" md="12" xs="12" className="text-justify col">
                  <p className="homeScreenAcademyBio">
                    Our goal at SyL Academy is to be consistent in providing
                    first-class medical spa trainings for our students.
                  </p>
                  <p className="homeScreenAcademyBio">
                    Are you ready for a change in careers? Or are you wanting to
                    add a new service to your existing menu? Our academy offers
                    a list of courses to help you open your own spa or learn a
                    new technique.
                  </p>
                  <p className="homeScreenAcademyBio">
                    Most of our courses will get you certified in as little as
                    just one day! We only organize small capacity trainings to
                    provide you with more wholesome 1:1 interaction with more
                    guidance.
                  </p>
                  <p className="homeScreenAcademyBio">
                    All courses provide you with certification and extended
                    support after class.
                  </p>
                  {/* div holding the button to go to the academy */}
                  <div className="text-center">
                    <Button
                      alt="Academy Courses Button"
                      id="BookNow"
                      onClick={() => {
                        navigate("/academy/permanentMakeup");
                        // FB_Pixel view content for the academy courses
                        ReactPixel.track(`ViewContent`, {
                          id: "HomeAcademyCourseButton",
                          content_name: `Home-Academy-Bio-Course`,
                          content_type: `Home-Academy-Bio-Course`,
                        });
                        // FB convertion api bViewContent for tje academy courses
                        facebookConversion(
                          "BookNow-HomeAcademyBio",
                          `ViewContent`
                        );
                      }}
                    >
                      Our Courses
                    </Button>
                  </div>
                </Col>
                {/* column for the SYL Academy bio image  */}
                <Col lg="6" md="12" xs="12" className="text-center col">
                  {/* SyL Academy bio img */}
                  <Image
                    fluid="true"
                    rounded="true"
                    alt="SYL Academy Image"
                    src={SYLhomeAcademyBioImg}
                  />
                </Col>
              </AcademyContentDiv>
            </Row>
            {/* </Row> */}
          </Container>
        </section>
        {/* !-- ======= End Sol y Luna Academy Bio Section ======= -- */}
        {/* !-- ======= Start Services Section on the Home Screen======= -- */}
        <section id="services" className="services">
          {/* Services container  */}
          <Container>
            {/* services title  */}
            <h4 className="servicesHomeSection" style={{ textAlign: "center" }}>
              Sol y Luna Services
            </h4>
            {/* services description  */}
            <Row>
              <p className="servicesHomedesc">
                Our MedSpa offers a wide variety of cosmetic and aesthetic
                services that include Permanent makeup, Injectables,
                Non-Invasive Body contouring, Teeth whitening and more!
              </p>
            </Row>
            <Row>
              {/* !-- ======= PERMANENT MAKE UP HOME SCREEN SECTION BOX on the Home Screen======= -- */}
              <Col md="6">
                <div
                  className="icon-box"
                  onClick={() => {
                    navigate("/services/permanentMakeup");
                    // FB_Pixel view content for SYL permanent makeUp tile
                    ReactPixel.track(`ViewContent`, {
                      id: "Home-services-permanentMakeup-tile",
                      content_name: `Home-Services-PermanentmakeUp`,
                      content_type: `Home-Services-PermanentmakeUp`,
                    });
                    // FB convertion api viewContent pmu service tile
                    facebookConversion("HomeServicesTilesPMU", `ViewContent`);
                  }}
                >
                  <FontAwesomeIcon className="icons" icon={faKissWinkHeart} />
                  <h4 className="icon-box-service">Permanent makeup</h4>
                  <p className="icon-box-service">
                    Save time on your make-up and wake up ready to take on the
                    day with style that requires no effort. Our Permanent makeup
                    services include Eyebrow Microblading, Powder Brows and Lip
                    Blush.
                  </p>
                </div>
              </Col>
              {/* !-- ======= AESTHETICS HOME SCREEN SECTION BOX on the Home Screen======= -- */}
              <Col md="6" mt="4">
                <div
                  className="icon-box"
                  onClick={() => {
                    navigate("/services/aesthetics");
                    // FB_Pixel view content for SYL Aesthetics tile
                    ReactPixel.track(`ViewContent`, {
                      id: "Home-services-aesthetics-tile",
                      content_name: `Home-Services-Aesthetics`,
                      content_type: `Home-Services-Aesthetics`,
                    });
                    // FB convertion api view content for Aesthetics tile
                    facebookConversion(
                      "HomeServicesTilesAesthetics",
                      `ViewContent`
                    );
                  }}
                >
                  <FontAwesomeIcon className="icons" icon={faSyringe} />
                  <h4 className="icon-box-service">Aesthetics</h4>
                  <p className="icon-box-service">
                    Say goodbye to stubborn wrinkles and lines or add some
                    volume to plump your lips or create a lift. Our Aesthetic
                    services include PRP Microneedling, Neurotoxin (Botox),
                    Dermaplaning, Dermal fillers and more.
                  </p>
                </div>
              </Col>
              {/* !-- ======= BODY CONTOURING HOME SCREEN SECTION BOX on the Home Screen======= -- */}
              <Col md="6" mt="4">
                <div
                  className="icon-box"
                  onClick={() => {
                    navigate("/services/bodyContouring");
                    // FB_Pixel view content for SYL bodyContouring tile
                    ReactPixel.track(`ViewContent`, {
                      id: "Home-services-bodyContouring-tile",
                      content_name: `Home-Services-BodyContouring`,
                      content_type: `Home-Services-BodyContouring`,
                    });
                    // FB convertion api viewContent for the body contouring
                    facebookConversion(
                      "HomeServicesTilesBodyContouring",
                      `ViewContent`
                    );
                  }}
                >
                  <FontAwesomeIcon className="icons" icon={faChildReaching} />
                  <h4 className="icon-box-service">
                    Non-Invasive Body contouring
                  </h4>
                  <p className="icon-box-service">
                    Say goodbye to stubborn fat, cellulite and sagging skin. Our
                    Non-Invasive Body Contouring services require no down time
                    and no surgery. We have multiple techniques to help you
                    reach your goals.
                  </p>
                </div>
              </Col>
              {/* !-- ======= TEETH WHITENING HOME SCREEN SECTION BOX on the Home Screen======= -- */}
              <Col md="6" mt="4">
                <div
                  onClick={() => {
                    navigate("/services/teethWhitening");
                    // FB_Pixel view content for SYL teethWhitening tile
                    ReactPixel.track(`ViewContent`, {
                      id: "Home-services-teethWhitening-tile",
                      content_name: `Home-Services-TeethWhitening`,
                      content_type: `Home-Services-TeethWhitening`,
                    });
                    // FB convertion api  viewContent for the teeth whitening tile
                    facebookConversion(
                      "HomeServicesTilesTeethWhitening",
                      `ViewContent`
                    );
                  }}
                  className="icon-box"
                >
                  <FontAwesomeIcon className="icons" icon={faTeeth} />
                  <h4>
                    <div className="icon-box-service">Teeth whitening</div>
                  </h4>
                  <p className="icon-box-service">
                    A good first impression may require a nice bright smile,
                    allow us to help you achieve that pearly white smile. If
                    you're feeling like you need to show out, Add some bling.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* !-- ======= End Services Section ======= -- */}
      </main>
    </MainHomeScreenDiv>
  );
}

export default HomeScreen;
//styling USING STYLED COMPONENTS
// main screen div
const MainHomeScreenDiv = styled.div``;
// academy content div
const AcademyContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;
