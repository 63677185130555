// Dependencies
import React, { useEffect } from "react";
import styled from "styled-components/macro";

function TermsAndConditionsScreen() {
  useEffect(() => {
    document.title = "Sol y Luna Beautique : Terms and Conditions";
  });
  // termly script function for the iframe to work

  const d = document;
  const s = "script";
  const id = "termly-jssdk";
  var js,
    tjs = d.getElementsByTagName(s)[0];

  js = d.createElement(s);
  js.id = id;
  js.src = "https://app.termly.io/embed-policy.min.js";
  tjs.parentNode.insertBefore(js, tjs);
  return (
    <TermsAndConditionsScreenMainDiv
      // terms n conditions main div with termly iframe
      name="termly-embed"
      data-id="ac7cc1bd-ea30-40ce-894a-0099ac712e0b"
      data-type="iframe"
    ></TermsAndConditionsScreenMainDiv>
  );
}

export default TermsAndConditionsScreen;
// terms and conditions main div styling
const TermsAndConditionsScreenMainDiv = styled.div`
  background-color: rgba(156, 128, 104, 0.9);
  min-height: 100vh;
  padding: 40px 40px 50px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
