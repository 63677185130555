import React, { useEffect } from "react";
import styled from "styled-components/macro";
// payment plan cherry screen
function PaymentPlans() {
  useEffect(() => {
    const script = document.createElement("script");

    script.innerHTML = `(function (w, d, s, o, f, js, fjs) {
      w[o] =
          w[o] ||
          function () {
              (w[o].q = w[o].q || []).push(arguments);
          };
      (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
      js.id = o;
      js.src = f;
      js.async = 1;
      fjs.parentNode.insertBefore(js, fjs);
  })(window, document, "script", "_hw", ' https://files.withcherry.com/widgets/widget.js');
  _hw(
      "init",
      {
          debug: false,
          variables: {
              slug: 'solylunabeautique',
              name: 'Sol Y Luna Beautique',
          },
          styles: {
              primaryColor: '#f4c5cc',
              secondaryColor: '#f4c5cc10',
              fontFamily: 'Open Sans',
          },
      },
      ["all", "hero", "howitworks", "testimony", "faq", "calculator"]
  );`;
    script.async = true;
    document.body.appendChild(script);
    // page title
    document.title = "Sol y Luna Beautique : Payment Plans";
    return script.remove();
  }, []);
  //   container styled-component with id=all for cherry script to load
  return <Container id="all" />;
}

export default PaymentPlans;
// styling container div
const Container = styled.div`
  margin-top: 88px;
`;
