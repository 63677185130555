//Dependecies
import React, { useEffect } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import styled from "styled-components/macro";
import ReactPixel from "react-facebook-pixel";

//Importing css
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Import icons from FontAwesome Solid
import {
  faMapLocation,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
// Importing SYL front door  image
import AboutUsImg from "../../assets/images/contactUs/SYLfront.jpg";
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { facebookConversion } from "../../tools/facebookConversion";

function ContactUsScreen() {
  useEffect(() => {
    document.title = "Sol y Luna Beautique : Contact Us";
  });
  return (
    // bs-main tag
    <main id="main">
      {/* separation div */}
      <div id="SeparadorInicial"></div>
      {/* !-- ======= Start Contact Section ======= -- */}
      <section id="contact" className="contact">
        {/* main bs- container  */}
        <Container>
          {/* contact title  */}
          <h3 className="services" style={{ textAlign: "center" }}>
            Contact Us
          </h3>
          {/* bs-main ROW  with the contact info */}
          <Row lg="12" md="12" xs="12" className="contactUsRow">
            {/* bs-col img with syl img   */}
            <ImageCol xl="6" lg="6" md="12" xs="12" className="col">
              <BioImage fluid="true" rounded="true" src={AboutUsImg} />
            </ImageCol>
            {/* Main bs-col info details col  */}
            <Col lg="6" md="12" xs="12" className="col contanct-info">
              {/* bs-row  */}
              <Row>
                {/* bs-col with syl info  */}
                <Col md="12" xs="12" className="info-col">
                  {/* div with class name icon-box holds all syl ingo address, email, phone number */}
                  <div className="icon-box">
                    {/* map fontawesome icon */}
                    <FontAwesomeIcon className="icons" icon={faMapLocation} />
                    {/* address title  */}
                    <h4>Our Address:</h4>
                    {/* div that holds the address  */}
                    <div
                      className="contact-address"
                      // FB_Pixel tracking contact address click
                      onClick={() => {
                        ReactPixel.track("Contact", {
                          content_name: "Contact Page Address",
                          content_type: "Contact Page Address Click",
                        });
                        // FB convertion api contact address
                        facebookConversion(`Contact-Address`, `Contact`);
                        window.open(
                          "https://www.google.com/maps/dir//Sol+y+Luna+Beautique+209+12th+St+%23207+Pflugerville,+TX+78660+United+States/@30.4430436,-97.6301873,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x8644cf96ed4a9e75:0x41363d9491dd1203!2m2!1d-97.6301873!2d30.4430436"
                        );
                      }}
                    >
                      {/* p tag with the encoded address  */}
                      <p className="contact-address">
                        &#032;&#050;&#048;&#057;&#032;&#083;&#032;&#049;&#050;&#084;&#104;&#032;&#083;&#116;&#032;&#083;&#117;&#105;&#116;&#101;&#032;#&#045;&#050;&#048;&#055;,
                      </p>
                      {/* p tag with the encoded address  */}
                      <p className="contact-address">
                        &#080;&#102;&#108;&#117;&#103;&#101;&#114;&#118;&#105;&#108;&#108;&#101;&#032;&#084;&#120;,&#032;&#055;&#056;&#054;&#054;&#048;
                      </p>
                    </div>
                    <br />
                    <br />
                    {/* fontawesome email/envelope icon  */}
                    <FontAwesomeIcon className="icons" icon={faEnvelope} />
                    {/* email title  */}
                    <h4>Email Us:</h4>
                    {/* p tag with the email encoded  */}
                    <p>
                      <a
                        className="contact-email"
                        href={() => false}
                        // FB_Pixel Contact email click
                        onClick={() => {
                          ReactPixel.track("Contact", {
                            content_name: "Contact Page Email",
                            content_type: "Contact Page Email Click",
                          });
                          // FB convertion api contact Email
                          facebookConversion(`Contact-Email`, `Contact`);
                          window.location =
                            "mailto:solylunabeautique@gmail.com";
                        }}
                      >
                        &#115;&#111;&#108;&#121;&#108;&#117;&#110;&#097;&#098;&#101;&#097;&#117;&#116;&#105;&#113;&#117;&#101;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
                      </a>
                    </p>
                    <br />
                    <br />
                    {/* fontAwesome phone icon  */}
                    <FontAwesomeIcon className="icons" icon={faPhone} />
                    {/* callUs title  */}
                    <h4>Call Us:</h4>
                    {/* p tag with the encoded phone number  */}
                    <p>
                      <a
                        className="contact-phone-number"
                        // FB_Pixel cotact phone number click
                        onClick={() => {
                          ReactPixel.track("Contact", {
                            content_name: "Contact Page PhoneNumber",
                            content_type: "Contact Page PhoneNumber Click",
                          });
                          // FB convertion api contact PhoneNumber
                          facebookConversion(`Contact-PhoneNumber`, `Contact`);
                          window.location = "tel:+1 737-742-0531";
                        }}
                        href={() => false}
                      >
                        +&#049;&#032;&#055;&#051;&#055;&#045;&#055;&#052;&#050;&#045;&#048;&#053;&#051;&#049;
                      </a>
                    </p>
                    {/* styled-Components-div social media Brands for option II */}
                    <SocialMediaBrandsDiv>
                      {/* styled-components-h3 social media brands  */}
                      <SocialMedialSendMessage>
                        Connect with us:
                      </SocialMedialSendMessage>
                      {/* fontawesome facebook icon */}
                      <FontAwesomeIcon
                        className="contactBrandsIcons"
                        icon={faFacebook}
                        // FB_Pixel FB icon click
                        onClick={() => {
                          ReactPixel.track("Contact", {
                            content_name: "Contact Page faceBookIcon",
                            content_type: "Contact Page faceBookIcon Click",
                          });
                          // FB convertion api contact FB
                          facebookConversion(`Contact-FB`, `Contact`);
                          window.open(
                            "https://www.facebook.com/solylunabeautiqueacademy/",
                            "_blank"
                          );
                        }}
                      />
                      {/* fontawesome IG icon */}
                      <FontAwesomeIcon
                        className="contactBrandsIcons"
                        icon={faInstagram}
                        // FB_Pixel IG icon click event
                        onClick={() => {
                          ReactPixel.track("Contact", {
                            content_name: "Contact Page Instagram",
                            content_type: "Contact Page Instagram Click",
                          });
                          // FB convertion api contact IG
                          facebookConversion(`Contact-IG`, `Contact`);
                          window.open(
                            "https://www.instagram.com/solylunabeautique_learn/?hl=en",
                            "_blank"
                          );
                        }}
                      />
                      {/* fontawesome TikTok icon */}
                      <FontAwesomeIcon
                        className="contactBrandsIcons"
                        icon={faTiktok}
                        onClick={() => {
                          // FB_Pixel tikTok icon click
                          ReactPixel.track("Contact", {
                            content_name: "Contact Page TikTok",
                            content_type: "Contact Page TikTok Click",
                          });
                          // FB convertion api contact TikTok
                          facebookConversion(`Contact-TikTok`, `Contact`);
                          window.open(
                            "https://www.tiktok.com/@solylunabeautique",
                            "_blank"
                          );
                        }}
                      />
                    </SocialMediaBrandsDiv>
                  </div>
                </Col>
              </Row>
              {/* closing row that holds image and syl info  */}
            </Col>
            {/* bs-col that hold the google maps iframe  */}
            <Col
              onClick={() => {
                // FB_Pixel contact page map click
                ReactPixel.track("Contact", {
                  content_name: "Contact Page GoogleMaps",
                  content_type: "Contact Page GoogleMaps Click",
                });
                // FB convertion api contact GoogleMaps
                facebookConversion(`Contact-GoogleMaps`, `Contact`);
              }}
              lg="12"
              md="12"
              xs="12"
              className="col contact-iframe-col"
            >
              {/* !-- ===== Google Maps iframe ===== --! */}
              <iframe
                title="iframe"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.7549395168935!2d-97.63237598456357!3d30.44304820658632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644cf96ed4a9e75%3A0x41363d9491dd1203!2sSol%20y%20Luna%20Beautique!5e0!3m2!1sen!2smx!4v1660753746220!5m2!1sen!2smx"
                loading="lazy"
                style={{
                  width: "100%",
                  height: "400px",
                  borderRadius: "6px",
                  boxShadow: "5px 5px 5px",
                }}
              ></iframe>
            </Col>
          </Row>
        </Container>
        {/* closing bs-container  */}
      </section>
      {/* !-- ======= End Contact Section ======= -- */}
    </main>
  );
}

export default ContactUsScreen;
// styling img bs-col
const ImageCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// styling the img
const BioImage = styled(Image)`
  border: 0;
  box-shadow: 5px 5px 5px;
`;
const SocialMediaBrandsDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 5px;
`;
const SocialMedialSendMessage = styled.h5`
  font-size: 1.2rem;
  font-weight: 700;
`;
