//Dependecies
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Container, Col, Row } from "react-bootstrap";

//Importing css
import "../../App.css";

// Importing team data
import { TeamData } from "./TeamData";
// importing the team member card component
import TeamMemberCard from "../../components/teamMemberCard/TeamMemberCard";

function TeamScreen() {
  useEffect(() => {
    document.title = "Sol y Luna Beautique : Our Team";
  });
  return (
    //Main Team screen div
    <MainTeamScreenDiv>
      {/* !-- ======= bootstrap main ======= -- */}
      <main id="main">
        <div id="SeparadorInicial"></div>
        {/* !-- ======= About Team Section ======= -- */}
        <section id="team" className="section team section-bg">
          {/* !-- ======= BS main container for team cards ======= -- */}
          <Container className="container">
            {/* !-- ======= screen title bs Row ======= -- */}
            <Row className="row content">
              {/* !-- ======= screen title bs Col ======= -- */}
              <Col lg="12" md="12" xs="12" className="text-justify col">
                {/* Team title */}
                <h3 className="academy">Our Team</h3>
              </Col>
            </Row>
            {/* !-- ======= Team screen Member cards BS-Row ======= -- */}
            <Row>
              {TeamData.employees.map((employee, index) => {
                return (
                  // team member card component
                  <TeamMemberCard
                    key={index}
                    employeeFirst={employee.employeeFirst}
                    employeeName={employee.employeeName}
                    employeeTitle={employee.employeeTitle}
                    employeeImg={employee.employeeImg}
                    employeeBioOne={employee.employeeBioOne}
                    employeeBioTwo={employee.employeeBioTwo}
                  />
                );
              })}
            </Row>
          </Container>
        </section>
        {/* !-- ======= End About Team Section ======= -- */}
      </main>
    </MainTeamScreenDiv>
  );
}

export default TeamScreen;

//Main Team screen div
const MainTeamScreenDiv = styled.div``;
