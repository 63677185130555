// Dependencies
import React, { useEffect } from "react";
import styled from "styled-components/macro";

function WebDisclaimerScreen() {
  useEffect(() => {
    document.title = "Sol y Luna Beautique : Web Disclaimer";
  });
  // termly script function for the iframe to work
  const d = document;
  const s = "script";
  const id = "termly-jssdk";
  var js,
    tjs = d.getElementsByTagName(s)[0];

  js = d.createElement(s);
  js.id = id;
  js.src = "https://app.termly.io/embed-policy.min.js";
  tjs.parentNode.insertBefore(js, tjs);
  return (
    // Disclaimer main div with termly iframe
    <WebDisclaimerScreenMainDiv
      name="termly-embed"
      data-id="29ab5636-d9f5-427f-a043-732bc0e1babb"
      data-type="iframe"
    ></WebDisclaimerScreenMainDiv>
  );
}

export default WebDisclaimerScreen;
// Web Disclaimer main div styling
const WebDisclaimerScreenMainDiv = styled.div`
  background-color: rgba(156, 128, 104, 0.9);
  min-height: 100vh;
  padding: 40px 40px 50px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
