// Dependencies and imports
import React from "react";
import styled from "styled-components/macro";
import { Button, Col, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { facebookConversion } from "../../tools/facebookConversion";

function AcademyCard({
  heading,
  image,
  body,
  price,
  bookingUrl,
  setIsModalOpen,
  courseName,
}) {
  return (
    // BS-row-12 for the academy card
    <Row lg={12} md={12} xs={12} className="row content academy-row">
      {/* bs-column styled using styled-componets for the course image  */}
      <ImgCol lg={4} md={6} xs={12} className="text-center">
        {/* bs-image for the course  */}
        <Image src={image} className="academy-img" />
      </ImgCol>
      {/* bs-col with course details  */}
      <CourseContentCol
        lg={8}
        md={6}
        xs={12}
        className="text-justify col academy"
      >
        {/* div for the course card title  */}
        <div className="academy-course-content">
          {/* course title  */}
          <h3>{heading} </h3>
        </div>
        {/* div with the course price, view details icon and course description  */}
        <div
          onClick={() => {
            setIsModalOpen(true);
            // FB_Pixel view content for academy course read more onClick
            ReactPixel.track(`ViewContent`, {
              id: "Academy-Course-readMore-Modal",
              content_name: `Academy-Course-readMore-${courseName}`,
              content_type: `Academy-Course-readMore-${courseName}`,
            });
            // FB convertion api viewContent academy courses
            facebookConversion("AcademyCourseReadMore", `ViewContent`);
          }}
          className="academyCourseCardText"
        >
          {/* div that holds price, view details and font awesome icon  */}
          <div className="academyViewDetails">
            {/* course price  */}
            <h6>{price}</h6>
            {/* view details and icon */}
            <div>
              View Details
              <FontAwesomeIcon
                className="academyViewDetailsIcon"
                icon={faList}
              />
            </div>
          </div>
          {/* course details body  */}
          <p>{body}</p>
        </div>
        {/* div with academy register button  */}
        <ButtonDiv className="text-center">
          <Button
            className="text-wrap"
            id="BookNow"
            onClick={() => {
              window.open(bookingUrl);
              // FB_Pixel AddToCart for academy course card register button
              ReactPixel.track(`AddToCart`, {
                id: "Academy-Course-RegisterNow-Button",
                content_name: `Academy-Course-RegisterNow-${courseName}`,
                content_type: `Academy-Course-RegisterNow-${courseName}`,
                currency: "USD",
                value: `${price}`,
              });
              // FB convertion api AddToCArt Academy course
              facebookConversion("AcademyCourseRegisterNow", `AddToCart`);
              // GA4 event
              ReactGA.event({
                name: `${courseName}`,
                category: "Academy",
                action: `Course Registration-${courseName}`,
                label: `Course Registration-Button-${courseName}`, // optional
              });
            }}
          >
            Register {courseName}
          </Button>
        </ButtonDiv>
      </CourseContentCol>
    </Row>
  );
}

export default AcademyCard;
// styled components styling
// bs-img col
const ImgCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;
// bs-course details
const CourseContentCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 20px 20px;
`;
const ButtonDiv = styled.div`
  display: flex;
`;
