// importing Dependencies
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import styled from "styled-components/macro";
// importing Logo for NavBar
import Logo from "../../assets/images/logo/sylLogo.png";
import LogoAcademy from "../../assets/images/logo/sylLogoAcademy.png";
//Importing css
import "../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import ReactPixel from "react-facebook-pixel";
import { facebookConversion } from "../../tools/facebookConversion";

// standard SYL navBar
function SyLnavBar(props) {
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  const [hamburgerMenu, setHamburgerMenu] = useState(false);

  useEffect(() => {
    setUrl(location.pathname);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    // bs-navbar component syl
    <Navbar collapseOnSelect expand="lg" fixed="top" variant="light">
      {/* bs-container for syl navbar  */}
      <Container className="navbarContainer">
        {/* bs-nav-brand Logo section syl  */}
        <Navbar.Brand href="/">
          {/* logo ing */}
          <img
            src={Logo}
            width="200"
            height="50"
            className="d-inline-block align-top"
            alt="Sol y Luna Logo"
          />
        </Navbar.Brand>
        {/* bs-navbar-toggle  */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          {/* styled-components span with the hamburger and x icon  */}
          <HamburgerIcon
            onClick={() => {
              setHamburgerMenu((curr) => !curr);
            }}
          >
            {/* icon Toggle conditional  */}
            {hamburgerMenu ? (
              <FontAwesomeIcon icon={faXmark} size="2x" />
            ) : (
              <FontAwesomeIcon icon={faBars} size="2x" />
            )}
          </HamburgerIcon>
        </Navbar.Toggle>
        <Navbar.Collapse
          className="navCollapse"
          id="collapseTest responsive-navbar-nav"
        >
          <Nav className="me-auto"></Nav>
          {/* bs-nav section with nav link syl */}
          <Nav>
            <Nav.Link href="/" className={url === "/" ? " active" : ""}>
              Home
            </Nav.Link>
            <NavDropdown
              title="About Us"
              id="basic-nav-dropdown"
              className={url === "/about" ? " show" : ""}
            >
              <NavDropdown.Item
                href="/about"
                className={url === "/about" ? " active" : ""}
              >
                About Us
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/team"
                className={url === "/team" ? " active" : ""}
              >
                Our Team
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              href="/academy/permanentMakeup"
              className={url === "/academy" ? " active" : ""}
            >
              Academy
            </Nav.Link>
            <Nav.Link
              href="/services/permanentMakeup"
              className={url === "/services" ? " active" : ""}
            >
              Services
            </Nav.Link>
            <Nav.Link
              href="/paymentPlans"
              className={url === "/paymentPlans" ? " active" : ""}
            >
              Payment Plans
            </Nav.Link>
            <Nav.Link
              href="/contactus"
              className={url === "/contact" ? " active" : ""}
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              href="/policies"
              className={url === "/policies" ? " active" : ""}
            >
              Policies
            </Nav.Link>
            {/*<Nav.Link href="/contactus">Contact Us</Nav.Link>*/}
            <Nav.Link
              href="https://solylunabeautiqueaustin.as.me/schedule.php"
              target="blank"
              className="getstarted"
              alt="Book Now Button"
              onClick={() => {
                // FB_Pixel AttToCart Pixel track event navBar bookNow button
                ReactPixel.track(`AddToCart`, {
                  id: "HomeBookNowButton",
                  content_name: `BookNow-NavBar`,
                  content_type: `BookNow-NavBar`,
                });
                // FB convertion api AddToCArt for the navBar button book now
                facebookConversion("BookNow-NavBar", `AddToCart`);
              }}
            >
              Book Now
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
// Academy navbar
function NavBarAcademy(props) {
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  const [hamburgerMenu, setHamburgerMenu] = useState(false);

  useEffect(() => {
    setUrl(location.pathname);
    window.scrollTo(0, 0);
  }, [location]);
  return (
    // bs-navbar component for academy
    <Navbar
      collapseOnSelect
      expand="lg"
      fixed="top"
      variant="light"
      className="navbar2"
    >
      {/* bs-container for academy navbar  */}
      <Container className="navbar2Container">
        {/* bs-nav-brand Logo section academy  */}
        <Navbar.Brand href="/">
          {/* academy logo */}
          <img
            src={LogoAcademy}
            width="280"
            height="45"
            className="d-inline-block align-top "
            alt="LogoAcademy"
          />
        </Navbar.Brand>
        {/* bs-navbar-toggle  */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          {/* styled-components span with the hamburger and x icon  */}
          <HamburgerIcon
            onClick={() => {
              setHamburgerMenu((curr) => !curr);
            }}
          >
            {/* icon Toggle conditional  */}
            {hamburgerMenu ? (
              <FontAwesomeIcon icon={faXmark} size="2x" />
            ) : (
              <FontAwesomeIcon icon={faBars} size="2x" />
            )}
          </HamburgerIcon>
        </Navbar.Toggle>
        <Navbar.Collapse className="navCollapse" id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          {/* bs-nav section with nav link syl */}
          <Nav>
            <Nav.Link href="/" className={url === "/" ? " active" : ""}>
              Home
            </Nav.Link>
            <NavDropdown
              title="About Us"
              id="basic-nav-dropdown"
              className={url === "/about" ? " show" : ""}
            >
              <NavDropdown.Item
                href="/about"
                className={url === "/about" ? " active" : ""}
              >
                About Us
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/team"
                className={url === "/team" ? " active" : ""}
              >
                Our Team
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              href="/academy/permanentMakeup"
              className={url === "/academy" ? " active" : ""}
            >
              Academy
            </Nav.Link>
            <Nav.Link
              href="/services/permanentMakeup"
              className={url === "/services" ? " active" : ""}
            >
              Services
            </Nav.Link>
            <Nav.Link
              href="/paymentPlans"
              className={url === "/paymentPlans" ? " active" : ""}
            >
              Payment Plans
            </Nav.Link>
            <Nav.Link
              href="/contactus"
              className={url === "/contact" ? " active" : ""}
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              href="/policies"
              className={url === "/policies" ? " active" : ""}
            >
              Policies
            </Nav.Link>
            {/*<Nav.Link href="/contactus">Contact Us</Nav.Link>*/}
            <Nav.Link
              href="https://solylunabeautiqueaustin.as.me/schedule.php"
              target="blank"
              className="getstarted"
              alt="Book Now Button"
              onClick={() => {
                // FB_Pixel AttToCart Pixel track event navBar bookNow button
                ReactPixel.track(`AddToCart`, {
                  id: "HomeBookNowButton",
                  content_name: `BookNow-NavBar`,
                  content_type: `BookNow-NavBar`,
                });
                // FB convertion api AddToCArt for the navBar button book now
                facebookConversion("BookNow-NavBar", `AddToCart`);
              }}
            >
              Book Now
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function Comparativa() {
  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);
  // Checking for academy in url to return academy navBar
  if (url && url.includes("/academy/")) {
    return <NavBarAcademy />;
  } else {
    return <SyLnavBar />;
  }
}
export default Comparativa;

// Hamburger icon
const HamburgerIcon = styled.span`
  background: none;
  border: none;
  color: #000; /* Fallback for older browsers */
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  @media (min-width: 991px) {
    display: none;
  }
`;
