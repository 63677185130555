// dependencies
import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components/macro";
import Carousel from "react-bootstrap/Carousel";
import RotateLoader from "react-spinners/RotateLoader";

// Google reviewsLogo
import { ReactComponent as GoogleWriteReviewLogo } from "../../assets/images/googleReviews/WriteUsAreviewGoogleLogo.svg";

const Reviews = () => {
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [index, setIndex] = useState(0);
  // BS-Carousel index
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    axios
      .get("https://sol-y-luna-server.onrender.com/reviews")
      .then((data) => {
        setLoading(false);
        setReviews(data.data.result.reviews || []);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    // Main Reviews div
    <MainReviewsDiv>
      {loading ? (
        // loading div
        <LoadingDiv>
          {/* Rotate loader from the react-spinner libary */}
          <RotateLoader color={" #F4C5CC"} loading={loading} size={15} />
        </LoadingDiv>
      ) : (
        // BS_Carousel with the reviews
        <StyledCarousel
          pause="hover"
          touch={true}
          activeIndex={index}
          onSelect={handleSelect}
          fade
          variant="light"
          interval={5000}
        >
          {/* mapping the reviewa data from the goggle api */}
          {reviews.map((review, reviewIndex) => {
            const stars = review.rating;
            return (
              // BS-Carousel item represent each individual google review
              <CarouselStyledItem key={`Review-${reviewIndex}`}>
                {/* the review div that holds the reviews inside the BS-Item  */}
                <Review>
                  {/* image div that holds the reviews profile image */}
                  <Image src={review.profile_photo_url} />
                  {/* h1 that holds the review authors name  */}
                  <Name>{review.author_name}</Name>
                  {/* div that holds the stars rating  */}
                  <Rating>
                    {/* stars map  */}
                    {[...Array(stars)].map((star, starIndex) => (
                      // div that holds the mapped stars
                      <RatingStarsDiv
                        className=""
                        key={`ReviewStar-${starIndex}`}
                      >
                        {star}⭐
                      </RatingStarsDiv>
                    ))}
                  </Rating>
                  {/* review body div  */}
                  <ReviewBody>{review.text}</ReviewBody>
                </Review>
                {/* google review log main div  */}
                <ReviewLogoMainDiv>
                  {/* Write a review google logo div  */}
                  <WriteReviewDiv>
                    {/* svg with logo  */}
                    <GoogleReviewsLogo
                      onClick={() => {
                        window.open("https://g.page/r/CQMS3ZGUPTZBEB0/review");
                      }}
                    />
                  </WriteReviewDiv>
                </ReviewLogoMainDiv>
              </CarouselStyledItem>
            );
          })}
        </StyledCarousel>
      )}
    </MainReviewsDiv>
  );
};

export default Reviews;
// main reviews div
const MainReviewsDiv = styled.div`
  min-height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 25px 0px 25px 0px;
  align-items: center;
  background-color: #9c8068;
`;
// loading spinner div
const LoadingDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;
// BS-Carousel tag styled
const StyledCarousel = styled(Carousel)`
  justify-content: center;
  min-height: 650px;
  width: 100%;
`;
// BS-Carousel-item tag styled
const CarouselStyledItem = styled(Carousel.Item)`
  width: 100%;
`;
// review main div
const Review = styled.div`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  color: white;
`;
// review image
const Image = styled.img`
  width: 50px;
  height: 50px;
  margin: 10px;
`;
// review name h1
const Name = styled.h1`
  font-size: 22px;
`;
// review raiting div
const Rating = styled.div`
  font-size: 28px;
  margin: 10px;
  display: flex;
  flex-direction: row;
`;
const RatingStarsDiv = styled.div`
  padding: 10px;
`;
// review body div
const ReviewBody = styled.div`
  padding: 35px 30px 0px 30px;
  margin: 0px 0px 30px 0px;
  width: 85%;
  text-align: justify;
  line-height: 25px;
  flex-wrap: wrap;
  height: 260px;
  overflow: scroll;
  font-size: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  @media (max-width: 480px) {
    height: 460px;
    font-size: large;
    padding-top: 30px;
  }
`;
// reviews google logo main div
const ReviewLogoMainDiv = styled.div`
  width: 100%;
  padding-bottom: 50px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
`;
// write a review div
const WriteReviewDiv = styled.div`
  padding-top: 25px;
  cursor: pointer;
`;
// logo styling
const GoogleReviewsLogo = styled(GoogleWriteReviewLogo)`
  width: 200px;
  height: auto;
  & :hover {
    opacity: 0.9;
    fill: #ffb339 !important;
    stroke: #ffb339 !important;
  }
`;
