// Dependencies and imports
import React from "react";
import styled from "styled-components/macro";
import { Col, Image, Button, Modal } from "react-bootstrap";

// Team Member Card
function TeamMemberCard({
  employeeFirst,
  employeeName,
  employeeTitle,
  employeeImg,
  employeeBioOne,
  employeeBioTwo,
}) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    // bootstrap Col for each individual team card
    <Col lg="4" md="6" xs="12" className="d-flex align-items-stretch">
      {/* main team card div */}
      <div className="member" data-aos="fade-up" data-aos-delay="100">
        {/* Div for the team member IMG */}
        <div className="member-img">
          <Image src={employeeImg} fluid="true" />
          {/* div for meet team member button  */}
          <div className="social">
            {/* meet button  */}
            <Button
              className="teamModalButton"
              onClick={() => setModalShow(true)}
            >
              Meet {employeeFirst}
            </Button>
            {/* Modal containing all the employees bio */}
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              employeeName={employeeName}
              employeeTitle={employeeTitle}
              employeeBioOne={employeeBioOne}
              employeeBioTwo={employeeBioTwo}
              employeeImg={employeeImg}
            />
          </div>
        </div>
        {/* team member card name and title div  */}
        <div className="member-info">
          {/* employee name */}
          <h4>{employeeName}</h4>
          {/* employee job title  */}
          <span>{employeeTitle}</span>
        </div>
      </div>
    </Col>
  );
}

export default TeamMemberCard;

// BootStrap Modal Component
function MyVerticallyCenteredModal(props) {
  return (
    // BS Modal using styled components
    <StyledModal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* ModalHeader  */}
      <Modal.Header closeButton>
        {/* Styled component div for the header employee name and job title  */}
        <StyledHeaderDiv>
          {/* employee name  */}
          <Modal.Title id="contained-modal-title-vcenter">
            {props.employeeName}
          </Modal.Title>
          {/* employee job title  */}
          <span>{props.employeeTitle}</span>
        </StyledHeaderDiv>
      </Modal.Header>
      {/* Modal Body  */}
      <Modal.Body>
        {/* styled component div for the modal body img  */}
        <StyledBodyDiv>
          {/* img  */}
          <Image
            roundedCircle="true"
            src={props.employeeImg}
            fluid="true"
            width="250"
          />
        </StyledBodyDiv>
        {/* employee bio  */}
        <p className="teamBioPtag">{props.employeeBioOne}</p>
        <p className="teamBioPtag">{props.employeeBioTwo}</p>
      </Modal.Body>
      {/* Modal footer with close button  */}
      <Modal.Footer>
        <Button onClick={props.onHide} className="teamModalButton">
          Close
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
}
// div inside the modal header
const StyledHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
// BootStrap Modal
const StyledModal = styled(Modal)`
  background-color: rgba(0, 0, 0, 0.8);
`;
// modal body div for image
const StyledBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px 40px 0px;
`;
