// dependencies
import * as React from "react";
import styled from "styled-components/macro";
// component imports
import NavBar from "./components/navBar/NavBar";
//import "./App.scss";
import Footer from "./components/footer/Footer";
import Reviews from "./components/reviews/Reviews";
// React router index routes
import RoutesIndex from "../src/RoutesIndex/RoutesIndex";
import "bootstrap/dist/css/bootstrap.min.css";
import InstagramFeed from "./components/instagramFeed/InstagramFeed";
import ReactPixel from "react-facebook-pixel";

function App() {
  // starting the FB-pixel connection
  const pixelId = process.env.REACT_APP_FB_PIXEL_ID;
  const advancedMatching = {
    // external_id: "external_id",
    // em: "some@email.com",
    // fn: "first_name",
    // ln: "last_name",
    // ge: "gender",
    // ph: "phone",
    // st: "state",
    // zp: "zip",
    // db: "birthday",
    // client_ip_address: "client_ip_address",
  }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init(pixelId, advancedMatching, options);
  ReactPixel.pageView(); // For tracking page view
  return (
    // App main Container
    <AppContainer>
      {/* navbar Component */}
      <NavBar />
      {/* react router routes  */}
      <RoutesIndex />
      {/* instagram feed component  */}
      <InstagramFeed />
      {/* Google reviews component */}
      <Reviews />
      {/* Footer component  */}
      <Footer />
    </AppContainer>
  );
}

export default App;
// styling the main app container
const AppContainer = styled.div`
  overflow-x: hidden;
  font-family: "Shippori Mincho", sans-serif;
`;
