// Dependencies and imports
import React from "react";
import styled from "styled-components/macro";
import { Button, Col, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { facebookConversion } from "../../tools/facebookConversion";

function ServicesCard({
  heading,
  image,
  body,
  price,
  bookingUrl,
  setIsModalOpen,
  serviceName,
}) {
  return (
    // bs-col service card
    <Col lg={6} md={12} xs={12} className="service-item">
      {/* bs-row service card */}
      <ServiceCardRow lg={12} md={12} xs={12}>
        {/* service image */}
        <Image className="services-img " src={image} />
        {/* div with service heading and price  */}
        <div className="services-content">
          {/* heading */}
          <h2>{heading}</h2>
          {/* service price  */}
          <span>{price}</span>
        </div>
        {/* service card body  */}
        <div
          onClick={() => {
            setIsModalOpen(true);
            // FB_Pixel view content for Service read more onClick
            ReactPixel.track(`ViewContent`, {
              id: "Service-readMore-modal",
              content_name: `Service-${serviceName}`,
              content_type: `Service-${serviceName}`,
              currency: "USD",
              value: `${price}`,
            });
            // FB convertion api read more Service
            facebookConversion(
              `Service-ReadMore-${serviceName}`,
              `ViewContent`
            );
          }}
          className="services-content-body"
        >
          {/* service Card Read more  */}
          <p className="serviceBody">
            {/* service card body  */}
            {body}
            ...read more
            {/* fontAwesome ReadMore Icon  */}
            <FontAwesomeIcon
              className="serviceReadMoreIcons"
              icon={faCircleInfo}
            />
          </p>
        </div>
        {/* div holding the actionUrl Button  */}
        <div className="text-center">
          {/* booking url Button opening blank window  */}
          <Button
            className="servicesButton"
            id="BookNow"
            onClick={() => {
              window.open(bookingUrl);
              // FB_Pixel AddToCart Pixel track event service card booking button
              ReactPixel.track(`AddToCart`, {
                content_name: `Service-${serviceName}`,
                content_type: `Services-${serviceName}`,
                currency: "USD",
                value: `${price}`,
              });
              // FB convertion api AddToCart Service
              facebookConversion(
                `Service-AddToCart-${serviceName}`,
                `AddToCart`
              );
              ReactGA.event({
                name: `${serviceName}`,
                category: "Service Appointment Booking",
                action: `Service Booking-${serviceName}`,
                label: `Service-Book-Appt-Button-${serviceName}`, // optional
              });
            }}
          >
            {" "}
            Book Appointment{" "}
          </Button>
        </div>
      </ServiceCardRow>
    </Col>
  );
}

export default ServicesCard;
// styled components styling
const ServiceCardRow = styled(Row)`
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
  display: flex;
  min-height: 420px;
  align-content: space-around;
  line-height: 30px;
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
  }
`;
