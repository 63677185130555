// dependencies
import React, { useEffect, useState } from "react";
import axios from "axios";
import styled, { keyframes } from "styled-components/macro";
import RingLoader from "react-spinners/RingLoader";

// import InstagramEmbed from "react-instagram-embed";
import InstagramEmbed from "react-instagram-embed";

// importing backgroundImage
import SYL_Brackground_patterns from "../../assets/images/backGroundImgs/sylBackgroundT.jpg";

function InstagramFeeds() {
  const [loading, setLoading] = useState(true);
  const [igGalleryFeed, setIgGalleryFeed] = useState([]);
  // secret keys from the dotenv
  const clientAccessToken = process.env.REACT_APP_CLIENT_ACESS_TOKEN;

  useEffect(() => {
    axios
      .get("https://sol-y-luna-server.onrender.com/instagram")
      .then((data) => {
        setLoading(true);
        setIgGalleryFeed(data.data.data || []);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    // Instagram Main div
    <InstagramFeedMainDiv>
      {/* ig feed title main div */}
      <IgFeedTitleMainDiv>
        {/* ig feed title with username  */}
        <IgFeedTitle>Follow us on Instagram: @solylunabeautique</IgFeedTitle>
      </IgFeedTitleMainDiv>
      {/* loading */}
      {loading ? (
        // loading div
        <LoadingDiv>
          <RingLoader
            color={" #9C8068"}
            loading={loading}
            // cssOverride={override}
            size={300}
          />
        </LoadingDiv>
      ) : (
        // Main IG timeLine Div called slider
        <Slider className="slider">
          {/* slider track div inside the slider div. this div is responsible for the scrolling animation */}
          <SliderTrack className="slider-track">
            {/* Mapping thru thr ig gallery data */}
            {igGalleryFeed.map((instagramData, index) => {
              return (
                // each ig post sits inside a slide div
                <Slide className="slide" key={index}>
                  {/* individual ig post */}
                  <InstagramEmbedStyled
                    url={instagramData.permalink}
                    clientAccessToken={clientAccessToken}
                    maxWidth={380}
                    hideCaption={true}
                    containerTagName="div"
                    protocol=""
                    injectScript
                    onLoading={() => {}}
                    onSuccess={() => {}}
                    onAfterRender={() => {}}
                    onFailure={() => {}}
                  />
                </Slide>
              );
            })}
          </SliderTrack>
        </Slider>
      )}
    </InstagramFeedMainDiv>
  );
}
// loading spinner div
const LoadingDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;
// Styling the main ig feed div
const InstagramFeedMainDiv = styled.div`
  background-image: url(${SYL_Brackground_patterns});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0px 50px 0px;
  align-items: center;
`;
// ig feed main title div
const IgFeedTitleMainDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  max-width: 610px;
  display: flex;
  justify-content: center;
  padding: 15px 2px;
  margin: 10px 0px;
  flex-wrap: wrap;
  align-self: center;
`;
// igFeedTitle h1
const IgFeedTitle = styled.h1`
  text-align: center;
  font-size: 26px;
  font-weight: 300;
  color: #000; /* Fallback for older browsers */
  color: rgba(0, 0, 0, 0.8);
  letter-spacing: 0.5px;
`;
// main slider div with styling for the edges of the div
const Slider = styled.div`
  display: flex;
  overflow: hidden;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  &:after,
  :before {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
  }
  &::before {
    left: 0;
    top: 0;
  }
  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
`;
// this is incharged of the scrolling using keyFrames
const scroll = keyframes`
 0% { transform: translateX(0) }
 100% { transform: translateX(calc(-250px * 6)) }
`;
// Slider Track div that uses the keyframes animations to scroll the div
const SliderTrack = styled.div`
  display: flex;
  justify-content: flex-start;
  width: calc(250px * 6);
  animation: ${scroll} 40s linear infinite;
  animation-delay: 10s;
`;
// each ig post has a div
const Slide = styled.div`
  height: 532px;
  width: auto;
  display: flex;
  padding: 0px 15px 0px 15px;
  transition: transform 1s;
  &:hover {
    perspective: 100px;
    transform: translateY(10px);
  }
`;
// instagram embed component
const InstagramEmbedStyled = styled(InstagramEmbed)`
  transition: transform 1s;
  &:hover {
    perspective: 100px;
    transform: translateZ(10px);
  }
`;

export default InstagramFeeds;
