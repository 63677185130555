// Dependencies
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import {
  Container,
  Col,
  Row,
  Button,
  Image,
  Modal,
  CloseButton,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

//Importing css
import "../../App.css";

// importing the card for each course
import AcademyCard from "../../components/academyCard/AcademyCard.jsx";

// importing academy course data file
import { AcademyCourseData } from "../../components/academyCard/academyData.js";

//Import icons from FontAwesome Solid
import {
  faPlus,
  faLayerGroup,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
//Import icons from FontAwesome Brands
import {
  faFacebook,
  faInstagram,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

// SYL Academy screen small bio image
import AcademyImg from "../../assets/images/homePage/SYLBioAcademy.jpg";
import { faSquare, faStar } from "@fortawesome/free-regular-svg-icons";
import { facebookConversion } from "../../tools/facebookConversion";

function AcademyScreen() {
  // Modal state handler
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // category selector state handler
  const [selectedCategory, setSelectedCategory] = React.useState(
    Object.keys(AcademyCourseData)[0]
  );

  const navigate = useNavigate();
  // modal
  const [modalContent, setModalContent] = React.useState({
    longDescription: "",
    whatIsAcademyBody: "",
    benefitsOfAcademyTitle: "",
    benefitsOfAcademyBody: "",
    afterCareTitle: "",
    afterCareBody: "",
    processOfAcademyTitle: "",
    processOfAcademyBody: "",
    price: "",
    bookingUrl: "",
    courseName: "",
  });
  const location = useLocation();
  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes("permanentMakeup")) {
      setSelectedCategory(Object.keys(AcademyCourseData)[0]);
    } else if (pathname.includes("bodyContouring")) {
      setSelectedCategory(Object.keys(AcademyCourseData)[1]);
    } else if (pathname.includes("aesthetics")) {
      setSelectedCategory(Object.keys(AcademyCourseData)[2]);
    } else if (pathname.includes("teethWhitening")) {
      setSelectedCategory(Object.keys(AcademyCourseData)[3]);
    } else {
      setSelectedCategory(Object.keys(AcademyCourseData)[0]);
    }
    document.title = "Sol y Luna Academy";
  }, [location]);
  function sendEmail() {
    window.location.assign("mailto:solylunabeautique@gmail.com");
  }
  return (
    // Main academy screen div styled-components
    <MainAcademyScreenDiv>
      {isModalOpen && (
        // BS-Modal component with course info
        <Modal
          show={setIsModalOpen}
          onHide={() => setIsModalOpen(false)}
          size="xl"
          id="academyModal"
          className="academyModal"
          scrollable={true}
          autoFocus={true}
          animation={true}
          restoreFocus={true}
        >
          {/* BS-Modal-Header component with course name/title */}
          <Modal.Header closeLabel="Close">
            {/* modal course title  */}
            <Modal.Title>{modalContent.courseName}</Modal.Title>
            {/* B-S modal close button  */}
            <CloseButton onClick={() => setIsModalOpen(false)} />
          </Modal.Header>
          {/* BS-Modal-Body component with course description, whatYouWillLearn, whatIsIncluded, howToGetStarted, cost and packageOptions */}
          <Modal.Body>
            {/* body bs-container  */}
            <Container>
              {/* body bs-row  */}
              <Row>
                {/* body bs-column 12 */}
                <Col md="12">
                  {/* courseBody styled component div  */}
                  <CourseBody>
                    {modalContent.academyDetails.longDescription}
                  </CourseBody>
                </Col>
              </Row>
            </Container>
            {/* fancy horizontal lineBreak */}
            <div className="fancy-divider">
              <div className="fancy-divider-shadow"></div>
            </div>
            {/* bs-Modal container with what will you learn  */}
            <Container>
              {/* bs-row whatWouldYouLearn */}
              <Row>
                {/* bs-col whatWouldYouLearn */}
                <Col md="12">
                  {/* styled-components div with whatWouldYouLearn title  */}
                  <CourseTitle>
                    {modalContent.academyDetails.whatWouldYouLearnTitle}
                  </CourseTitle>
                  {/* mapping thru the whatWouldYouLearn list and returning data and icon  */}
                  {modalContent.academyDetails.whatWouldYouLearn.map(
                    (learn, learnIndex) => {
                      return (
                        // styled-components div that holds whatWouldYouLearn list data
                        <CourseDivList key={`CourseLearn-${learnIndex}`}>
                          <FontAwesomeIcon
                            className="academyIcons"
                            icon={faPlus}
                          />
                          {/* styled-components div holds the whatWouldYouLearn list  */}
                          <CourseList>{learn}</CourseList>
                        </CourseDivList>
                      );
                    }
                  )}
                </Col>
              </Row>
              {/* fancy horizontal lineBreak */}
              <div className="fancy-divider">
                <div className="fancy-divider-shadow"></div>
              </div>
            </Container>
            {/* Modal bs-container with whatIsIncluded section  */}
            <Container>
              {/* bs-row whatIsIncluded */}
              <Row>
                {/* bs-col-12 whatIsIncluded */}
                <Col md="12">
                  {/* styled-component div whatIsIncluded title */}
                  <CourseTitle>
                    {modalContent.academyDetails.whatIsIncludedTitle}
                  </CourseTitle>
                  {/* mapping thru the whatIsIncluded list data  */}
                  {modalContent.academyDetails.whatIsIncluded.map(
                    (includes, includesIndex) => {
                      return (
                        // styled-component div that holds whatIsIncluded list data
                        <CourseDivList key={`CourseLearn-${includesIndex}`}>
                          {/* fontAwesome icon  */}
                          <FontAwesomeIcon
                            className="academyIcons"
                            icon={faPlus}
                          />
                          {/* styled-components div hold the whatIsIncluded list  */}
                          <CourseList>{includes}</CourseList>
                        </CourseDivList>
                      );
                    }
                  )}
                </Col>
              </Row>
              {/* fancy horizontal lineBreak */}
              <div className="fancy-divider">
                <div className="fancy-divider-shadow"></div>
              </div>
            </Container>

            {/* bs-container howToGetStarted/ how to register section  */}
            <Container>
              {/* bs-row howToGetStarted */}
              <Row>
                {/* bs-col-12 howToGetStarted */}
                <Col md="12">
                  {/* styled-components div with howToGetStarted title  */}
                  <CourseTitle>
                    {modalContent.academyDetails.howToGetStartedTitle}{" "}
                  </CourseTitle>
                  {/* styled-components div with howToGetStarted/register  */}
                  <HowToGetStartedDiv id="how-to-get-started">
                    {/* mapping the howToGetStarted options  */}
                    {modalContent.academyDetails.howToGetStarted.map(
                      (how, howIndex) => {
                        // checking for option index to return the actionUrl Button
                        if (howIndex === 0) {
                          return (
                            // styled-component-div with howToGetStarted/register steps
                            <CourseRegisterDiv
                              key={`howToGetStarted-${howIndex}`}
                            >
                              {/* styled-component h3 option title  */}
                              <OptionTitle>Option I:</OptionTitle>
                              {/* mapping through how option I steps  */}
                              {how.steps.map((step, stepIndex) => {
                                // checking for "Follow" in the steps array to return step without Icon
                                if (step.includes("Follow") === true) {
                                  return (
                                    <h4 key={`stepIndexIoption-${stepIndex}`}>
                                      {step}
                                    </h4>
                                  );
                                }
                                // returning the remaining steps with Icon
                                return (
                                  // styled-component-div with register option I steps
                                  <CourseDivStepList
                                    key={`stepIndexIoptionDiv-${stepIndex}`}
                                  >
                                    {/* fontawesome icon square with check mark  */}
                                    <FontAwesomeIcon
                                      className="academyIcons"
                                      icon={faSquareCheck}
                                    />
                                    {/* styled-components-div howToGetStarted/register steps list option I  */}
                                    <StepList
                                      key={`stepIndexIoption-${stepIndex}`}
                                    >
                                      {step}
                                    </StepList>
                                  </CourseDivStepList>
                                );
                              })}
                              {/* div holding the actionUrl Button  */}
                              <div className="text-center">
                                {/* actionUrl Button opening blank window  */}
                                <Button
                                  id="BookNow"
                                  onClick={() => {
                                    // FB_Pixel AttToCart Pixel track event Academy Screen course register modal-button
                                    ReactPixel.track(`AddToCart`, {
                                      id: "AcademyRegisterNowCourseModalButton",
                                      content_name: `${modalContent.courseName}-RegisterNow-Modal`,
                                      content_type: `${modalContent.courseName}-RegisterNow-Modal`,
                                    });
                                    // FB convertion api AddToCArt register for course
                                    facebookConversion(
                                      `${modalContent.courseName}-RegisterNow-Modal`,
                                      `AddToCart`
                                    );
                                    window.open(how["actionUrl"]);
                                  }}
                                >
                                  {" "}
                                  Register Now{" "}
                                </Button>
                              </div>
                            </CourseRegisterDiv>
                          );
                        }
                        // else returning Option II with Brand Icon for a direct message
                        else {
                          return (
                            // styled-component-div with howToGetStarted/register steps Option II
                            <CourseRegisterDiv
                              key={`howToGetStarted-${howIndex}`}
                            >
                              {/* styled-components-h3 option II title */}
                              <OptionTitle>Option II:</OptionTitle>
                              {/* mapping through how option II steps  */}
                              {how.steps.map((step, stepIndex) => {
                                // checking for "Follow" in the steps array to return step without Icon
                                if (step.includes("Follow") === true) {
                                  return (
                                    // returning step no icon
                                    <h4 key={`stepIndexIIoption-${stepIndex}`}>
                                      {step}
                                    </h4>
                                  );
                                }
                                // returning the remaining steps with Icon option II
                                return (
                                  // styled-component-div with register option II steps
                                  <CourseDivStepList
                                    key={`stepIndexIIoptionDiv-${stepIndex}`}
                                  >
                                    {/* fontawesome icon square with check mark  */}
                                    <FontAwesomeIcon
                                      className="academyIcons"
                                      icon={faSquareCheck}
                                    />
                                    {/* styled-components-div howToGetStarted/register steps list  */}
                                    <StepList
                                      key={`stepIndexIIoption-${stepIndex}`}
                                    >
                                      {step}
                                    </StepList>
                                  </CourseDivStepList>
                                );
                              })}
                              {/* styled-Components-div social media Brands for option II */}
                              <SocialMediaBrandsDiv>
                                {/* styled-components-h3 social media brands  */}
                                <SocialMedialSendMessage>
                                  Click to send a Message:
                                </SocialMedialSendMessage>
                                {/* fontawesome facebook icon */}
                                <FontAwesomeIcon
                                  className="academyBrandsIcons"
                                  icon={faFacebook}
                                  onClick={() =>
                                    window.open(
                                      "https://www.facebook.com/solylunabeautiqueacademy/",
                                      "_blank"
                                    )
                                  }
                                />
                                {/* fontawesome IG icon */}
                                <FontAwesomeIcon
                                  className="academyBrandsIcons"
                                  icon={faInstagram}
                                  onClick={() =>
                                    window.open(
                                      "https://www.instagram.com/solylunabeautique_learn/?hl=en",
                                      "_blank"
                                    )
                                  }
                                />
                                {/* fontawesome google icon */}
                                <FontAwesomeIcon
                                  className="academyBrandsIcons"
                                  icon={faGoogle}
                                  onClick={() => window.open(sendEmail())}
                                />
                              </SocialMediaBrandsDiv>
                            </CourseRegisterDiv>
                          );
                        }
                      }
                    )}
                  </HowToGetStartedDiv>
                </Col>
              </Row>
              {/* fancy horizontal lineBreak */}
              <div className="fancy-divider">
                <div className="fancy-divider-shadow"></div>
              </div>
            </Container>
            {/* {modalContent.body} */}
            <Container>
              {/* bs-row packageOptions */}
              <Row id="packageOptions" className="academy">
                {/* mapping through how packageOptions  */}
                {modalContent.packageOptions.map(
                  (packageOption, packageIndex) => {
                    return (
                      // bs-col packageOption col-4
                      <Col
                        md="12"
                        className="packageOptionsCol"
                        key={`package-${packageIndex}`}
                      >
                        {/* styled-components-div packageOptions package title  */}
                        <CourseTitle>{packageOption.package}</CourseTitle>
                        <div>{packageOption.packageDetails}</div>
                        <div>{packageOption.price}</div>
                        {/* if kitTitle true render  */}
                        {packageOption.kitTitle && (
                          <div id="kit" className="academyKitModalDiv">
                            {/* styled-components-div for kit Title and Icon  */}
                            <AcademyModalKitTitleDiv>
                              {/* styled-components-div kit Title  */}
                              <CourseTitle>
                                {packageOption.kitTitle}
                              </CourseTitle>
                              {/* Icon  */}
                              <FontAwesomeIcon
                                onClick={() => setIsModalOpen(true)}
                                className="academyInfoIcon"
                                icon={faLayerGroup}
                              />
                            </AcademyModalKitTitleDiv>
                            <div>{packageOption.kitDetails}</div>
                            {/* div for what kit includes  */}
                            <div>
                              {/* mapping through how packageOption kit includes  */}
                              {packageOption.kitIncludes.map(
                                (kit, kitIndex) => {
                                  return (
                                    // div with each kit item and icon
                                    <div
                                      className="academyModalKitItems"
                                      key={`package-${packageIndex}-kit-${kitIndex}`}
                                    >
                                      {/* kit item icon  */}
                                      <FontAwesomeIcon
                                        className="academyPackageKitIcons"
                                        icon={faPlus}
                                      />
                                      {/* kit item */}
                                      <p>{kit}</p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        )}
                      </Col>
                    );
                  }
                )}
              </Row>
              {/* conditional rendering fancy divider only if we have options available  */}
              {modalContent.packageOptions.length > 1 && (
                // fancy div divider
                <div className="fancy-divider">
                  <div className="fancy-divider-shadow"></div>
                </div>
              )}
            </Container>
            {/* {bs-container with classRequirements } */}
            <Container>
              {/* bs-row classRequirements */}
              <Row id="classRequirements" className="academy">
                {/* mapping through classRequirements  */}
                {modalContent.academyDetails.classRequirements.map(
                  (classRequirement, classRequirementsIndex) => {
                    return (
                      // bs-col classRequirements col-12
                      <Col
                        md="12"
                        className="packageOptionsCol"
                        key={`classRequirement-${classRequirementsIndex}`}
                      >
                        {/* checking for classRequirements title = true and rendering */}
                        {modalContent.academyDetails.classRequirementsTitle && (
                          // div for classRequirement
                          <div id="kit" className="academyKitModalDiv">
                            {/* styled-components div for title and icon  */}
                            <AcademyModalKitTitleDiv>
                              {/* styled-components classRequirement title  */}
                              <CourseTitle>
                                {
                                  modalContent.academyDetails
                                    .classRequirementsTitle
                                }
                              </CourseTitle>
                              {/* title star icon  */}
                              <FontAwesomeIcon
                                className="academyInfoIcon"
                                icon={faStar}
                              />
                            </AcademyModalKitTitleDiv>
                            {/* div for classRequirement list and icon  */}
                            {classRequirement && (
                              <div>
                                <FontAwesomeIcon
                                  className="academyInfoIcon"
                                  icon={faSquare}
                                />
                                {/* classRequirement list/items  */}
                                {classRequirement}
                              </div>
                            )}
                          </div>
                        )}
                      </Col>
                    );
                  }
                )}
              </Row>
            </Container>
            {/* div holding the actionUrl Button  */}
            <div className="text-center">
              {/* booking url Button opening blank window  */}
              <Button
                id="BookNow"
                onClick={() => {
                  window.open(modalContent.bookingUrl);
                  // FB_Pixel AttToCart Pixel track event Academy Screen course register modal-button
                  ReactPixel.track(`AddToCart`, {
                    id: "AcademyRegisterNowCourseModalButton",
                    content_name: `${modalContent.courseName}-RegisterNow-Modal`,
                    content_type: `${modalContent.courseName}-RegisterNow-Modal`,
                  });
                  // FB convertion api AddToCArt register for course
                  facebookConversion(
                    `${modalContent.courseName}-RegisterNow-Modal`,
                    `AddToCart`
                  );
                  // reactga4 event for the service booking button
                  ReactGA.event({
                    name: `${modalContent.courseName}`,
                    category: "Academy",
                    action: `Course Registration-${modalContent.courseName}`,
                    label: `Course Registration-Button-${modalContent.courseName}`, // optional
                  });
                }}
              >
                {" "}
                Register Now{" "}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {/* =========END of MODAL=============== */}
      {/* ==========ACADEMY SCREEN Start ================= */}
      <main id="main">
        <div id="SeparadorInicialAcademy"></div>
        <section id="academy" className="section academy section-bg-academy">
          {/* bs-container for academy screen with small bio, img  */}
          <Container className="container">
            {/* bs-row  */}
            <Row className="row content">
              {/* academy title  */}
              <h3 className="academy academyPageTitle">Sol y Luna Academy.</h3>
              {/* <Row className="content academy-reverse "> */}
              <AcademyContentDiv className="academy-reverse ">
                {/* bs-col with academy small bio */}
                <Col lg="6" md="12" xs="12" className="text-justify col">
                  <p className="homeScreenAcademyBio">
                    We believe everyone deserves quality education. Our
                    instructors stay on top of the latest treatment trends and
                    prioritize continuing education to provide the best.
                  </p>
                  <p className="homeScreenAcademyBio">
                    We offer the latest in Advanced Aesthetic courses and
                    feature a list of trainings that can be scheduled privately
                    or in a group setting. We are committed to providing quality
                    training and support to all of our students.
                  </p>
                  {/* acdemy register button div  */}
                  <div className="text-center">
                    <Button
                      id="BookNow"
                      onClick={() => {
                        window.open(
                          "https://solylunabeautiqueaustin.as.me/sylacademy",
                          "_blank"
                        );
                        // FB_Pixel AttToCart Pixel track event Academy Screen bio button
                        ReactPixel.track(`AddToCart`, {
                          id: "AcademyRegisterNowBioButton",
                          content_name: `RegisterNow-AcademyScreen`,
                          content_type: `RegisterNow-AcademyScreen`,
                        });
                        // FB convertion api AddToCArt register for course in bio
                        facebookConversion(
                          `${modalContent.courseName}-RegisterNow-Modal`,
                          `AddToCart`
                        );
                        // reactga4 event for the service booking button
                        ReactGA.event({
                          name: "Academy Screen Register Button",
                          category: "Academy",
                          action: "Academy Screen Register Button",
                          label: "Academy Screen Register Button", // optional
                        });
                      }}
                    >
                      {" "}
                      Register Now{" "}
                    </Button>
                  </div>
                </Col>
                {/* bs-col with academy IMG  */}
                <Col lg="6" md="12" xs="12" className="text-center col">
                  <Image fluid="true" rounded="true" src={AcademyImg} />
                </Col>
              </AcademyContentDiv>
            </Row>
          </Container>
        </section>
        {/* academy screen course section  */}
        <section id="academy" className=" academy   ">
          {/* bs-container for courses  */}
          <Container className="container">
            <h3 className="coursePageTitle"> Courses </h3>
            {/* styled-component-div for academy course card  */}
            <AcademyCardMainDiv>
              {/* styled-component-div for academy course category picker  */}
              <AcademyCategoryDiv>
                {/* styled-component-div course category picker  */}
                <CategoryPicker>
                  {/* mapping through categories  */}
                  {Object.keys(AcademyCourseData).map((category) => {
                    return (
                      // styled-component-div for course category
                      <Category
                        key={`category-${category}`}
                        isSelected={selectedCategory === category}
                        onClick={() => {
                          // FB_Pixel view content for SYL permanent makeUp tile
                          // ReactPixel.track(`ViewContent`, {
                          //   id: "Academy-Course-Category-Selection",
                          //   content_name: `Academy-Course-Category-${category}`,
                          //   content_type: `Academy-Course-Category-${category}`,
                          // });
                          // category switch
                          let url = "/academy/";
                          switch (category) {
                            case "Permanent Make Up":
                              url += "permanentMakeup";
                              break;
                            case "Body Contouring":
                              url += "bodyContouring";
                              break;
                            case "Aesthetics":
                              url += "aesthetics";
                              break;
                            case "Teeth Whitening":
                              url += "teethWhitening";
                              break;
                            default:
                              url += "permanentMakeup";
                              break;
                          }
                          navigate(url);
                        }}
                      >
                        {category}
                      </Category>
                    );
                  })}
                </CategoryPicker>
                {/* styled-component-div for course card */}
                <CardsContainer>
                  {/* mapping through for each course in each categories  */}
                  {AcademyCourseData[selectedCategory].map((course, index) => {
                    return (
                      //  styled-component-div  Academy course card
                      <AcademyCard
                        key={`${course.courseName}-${index}`}
                        heading={course.courseName}
                        image={course.image}
                        body={course.smallDescription}
                        price={course.price}
                        bookingUrl={course.bookingUrl}
                        courseName={course.courseName}
                        setIsModalOpen={() => {
                          setModalContent(course);
                          setIsModalOpen(true);
                          // react GA$ ever for Service details
                          ReactGA.event({
                            name: `${course.courseName}`,
                            category: "Academy",
                            action: `Course Registration-Details-${course.courseName}`,
                            label: `Course Registration-Details-${course.courseName}`, // optional
                          });
                        }}
                      />
                    );
                  })}
                </CardsContainer>
              </AcademyCategoryDiv>
            </AcademyCardMainDiv>
          </Container>
        </section>
      </main>
      {/* using the react-router Outlet  */}
      <Outlet />;
    </MainAcademyScreenDiv>
  );
}

export default AcademyScreen;
//styling
const MainAcademyScreenDiv = styled.div`
  background-color: black;
`;

const AcademyCardMainDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;
const AcademyCategoryDiv = styled.div`
  padding: 20px 0;
  margin: 20px 0px 150px 0px;
  overflow: hidden;
  position: relative;
`;
const CategoryPicker = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
`;
const Category = styled.div`
  padding: 8px 25px;
  margin-bottom: 1em;
  border-radius: 4px;
  margin-left: 30px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? "#FFB339" : "#9C8068")};
  color: #fff;
  &:hover {
    color: #fff;
    background-color: #ffb339;
  }
`;
const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`;
const CourseTitle = styled.div`
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 10px;
`;
const CourseBody = styled.div`
  padding-left: 10px;
  line-height: 30px;
`;
const CourseDivList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
`;
const CourseDivStepList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
`;
const CourseList = styled.div`
  padding-left: 10px;
  line-height: 20px;
`;
const CourseRegisterDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 50%;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    padding: 10px;
    min-width: 100%;
  }
`;
const StepList = styled.div`
  padding-left: 10px;
`;
const HowToGetStartedDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;
const AcademyModalKitTitleDiv = styled.div`
  background-color: #9c8068;
  border-radius: 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  border-radius: 8px 0px;
  margin: 20px 0px 20px 0px;
`;

const SocialMediaBrandsDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
const SocialMedialSendMessage = styled.h3`
  padding: 0px 5px 0px 8px;
  font-size: 1.2rem;
  font-weight: 700;
`;
const OptionTitle = styled.h3`
  padding: 0px 5px 0px 8px;
  font-size: 24px;
  font-weight: 700;
`;
const AcademyContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;
