// packages dependencies
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
// nav bar screen imports
import HomeScreen from "../Screens/homeScreen/HomeScreen";
import AboutScreen from "../Screens/aboutScreen/AboutScreen";
import TeamScreen from "../Screens/teamScreen/TeamScreen";
import ServicesScreen from "../Screens/servicesScreen/ServicesScreen";
import AcademyScreen from "../Screens/academyScreen/AcademyScreen";
import PolicyScreen from "../Screens/policyScreens/PolicyScreen";
import ContactUsScreen from "../Screens/contactUsScreen/ContactUsScreen";
// legal forms imports
import PrivacyPolicyScreen from "../Screens/legal/privacyPolicyScreen/PrivacyPolicyScreen";
import TermsAndConditionsScreen from "../Screens/legal/termsAndConditionsScreen/TermsAndConditionsScreen";
import ShippingPolicyScreen from "../Screens/legal/shippingPolicy/ShippingPolicyScreen";
import WebDisclaimerScreen from "../Screens/legal/webSiteDisclaimerScreen/WebDisclaimerScreen";
import ReturnPolicyScreen from "../Screens/legal/returnPolicyScreen/ReturnPolicyScreen";
// importing Cards
import ReactGA from "react-ga4";
import PaymentPlans from "../Screens/paymentPlansScreen/PaymentPlans";

const RoutesIndex = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.set({ hitType: "pageview", page: location.pathname });
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, []);
  return (
    // calling routes from react router
    <Routes>
      {/* navbar routes for home, about, services, academy,policies and contact us  */}
      <Route exact strict path="/" element={<HomeScreen />} />
      <Route path="/home" element={<HomeScreen />} />
      <Route path="/about" element={<AboutScreen />} />
      <Route path="/team" element={<TeamScreen />} />
      {/* Services Screen  */}
      <Route path="/services/:service" element={<ServicesScreen />} />
      <Route path="/paymentPlans" element={<PaymentPlans />} />
      {/* academy screen */}
      <Route path="/academy/:course" element={<AcademyScreen />} />
      <Route path="/policies" element={<PolicyScreen />} />
      <Route path="/contactus" element={<ContactUsScreen />} />

      {/* ==== legal routes for privacy and policy, terms and conditions,  ==== */}
      <Route path="/legal/:privacypolicy" element={<PrivacyPolicyScreen />} />
      <Route
        path="/legal/termsandconditions"
        element={<TermsAndConditionsScreen />}
      />
      <Route path="/legal/shippingpolicy" element={<ShippingPolicyScreen />} />
      <Route path="/legal/disclaimer" element={<WebDisclaimerScreen />} />
      <Route path="/legal/returnpolicy" element={<ReturnPolicyScreen />} />
    </Routes>
  );
};

export default RoutesIndex;
