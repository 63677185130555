// Dependencies
import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Container, Col, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import {
  faCirclePlus,
  faStarOfLife,
  faCircleQuestion,
  faHandHoldingMedical,
  faBriefcaseMedical,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
// import modals
import Modal from "react-bootstrap/Modal";
//Importing css
import "../../App.css";
import ServicesCard from "../../components/servicesCard/ServicesCard.jsx";

// background import
import { ServicesData } from "../../components/servicesCard/servicesData.js";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { faHourglassHalf } from "@fortawesome/free-regular-svg-icons";
import { facebookConversion } from "../../tools/facebookConversion";

function ServicesScreen() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(
    Object.keys(ServicesData)[0]
  );

  const navigate = useNavigate();

  const [modalContent, setModalContent] = React.useState({
    whatIsServiceTitle: "",
    whatIsServiceBody: "",
    benefitsOfServiceTitle: "",
    benefitsOfServiceBody: "",
    afterCareTitle: "",
    afterCareBody: "",
    processOfServiceTitle: "",
    processOfServiceBody: "",
    price: "",
    bookingUrl: "",
    serviceName: "",
  });
  const location = useLocation();
  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.includes("permanentMakeup")) {
      setSelectedCategory(Object.keys(ServicesData)[0]);
    } else if (pathname.includes("bodyContouring")) {
      setSelectedCategory(Object.keys(ServicesData)[1]);
    } else if (pathname.includes("aesthetics")) {
      setSelectedCategory(Object.keys(ServicesData)[2]);
    } else if (pathname.includes("teethWhitening")) {
      setSelectedCategory(Object.keys(ServicesData)[3]);
    } else {
      setSelectedCategory(Object.keys(ServicesData)[0]);
    }
    document.title = "Sol y Luna Beautique : Services";
    window.scrollTo(0, 0);
  }, [location]);

  return (
    // styled-components service screen div
    <MainServiceScreenDiv>
      {/* checking modal state and rendering  */}
      {isModalOpen && (
        // bs-modal service modal
        <Modal
          show={setIsModalOpen}
          onHide={() => setIsModalOpen(false)}
          size="xl"
          id="serviceModal"
          className="serviceModal"
          scrollable={true}
          autoFocus={true}
          animation={true}
          restoreFocus={true}
        >
          {/* bs-modal-header with service title, icon and close button  */}
          <Modal.Header
            closeButton={() => setIsModalOpen(false)}
            closeLabel="Close"
          >
            {/* font awesome icon starOfLife  */}
            <FontAwesomeIcon className="servicesIcons" icon={faStarOfLife} />
            {/* bs-modal-title service name  */}
            <Modal.Title>{modalContent.serviceName}</Modal.Title>
          </Modal.Header>
          {/* bs-modal-body with service details  */}
          <Modal.Body>
            {/* bs-container fot the whatIsService details  */}
            <Container>
              {/* bs-row for the whatIsService details  */}
              <Row>
                {/* bs-col with whatIsService title and body  */}
                <Col md="12">
                  {/* styled-component div for modal whatIsServiceTitle */}
                  <SectionTitle>
                    {modalContent.servicesDetails.whatIsServiceTitle}
                    {/* font awesome moon icon  */}
                    <FontAwesomeIcon
                      className="serviceTitleIcons"
                      icon={faCircleQuestion}
                    />
                  </SectionTitle>
                  {/* styled-component div with whatIsServiceBody and icon  */}
                  <SectionBody>
                    {/* fontAwesome circle icon */}
                    <FontAwesomeIcon
                      className="serviceBodyIcons"
                      icon={faCirclePlus}
                    />
                    {modalContent.servicesDetails.whatIsServiceBody}
                  </SectionBody>
                </Col>
              </Row>
              {/* fancy horizontal lineBreak */}
              <div className="service-fancy-divider">
                <div className="service-fancy-divider-shadow"></div>
              </div>
            </Container>
            {/* bs-container for benefitsOfService section  */}
            <Container>
              {/* bs-row benefitsOfService */}
              <Row>
                {/* bs-col benefitsOfService */}
                <Col md="12">
                  {/* styled-component-div for the benefitsOfServiceTitle  */}
                  <SectionTitle>
                    {/* benefitsOfServiceTitle */}
                    {modalContent.servicesDetails.benefitsOfServiceTitle}
                    {/* fontawesome Icon circleQuestion  */}
                    <FontAwesomeIcon
                      className="serviceTitleIcons"
                      icon={faCircleQuestion}
                    />
                  </SectionTitle>
                  {/* styled-component div with benefitsOfServiceBody and icon  */}
                  <SectionBody>
                    {/* fontAwesome circle icon */}
                    <FontAwesomeIcon
                      className="serviceBodyIcons"
                      icon={faCirclePlus}
                    />
                    {/* benefitsOfServiceBody */}
                    {modalContent.servicesDetails.benefitsOfServiceBody}
                  </SectionBody>
                </Col>
              </Row>
              {/* fancy horizontal lineBreak */}
              <div className="service-fancy-divider">
                <div className="service-fancy-divider-shadow"></div>
              </div>
            </Container>
            {/* bs-container afterCare section  */}
            <Container>
              {/* bs-row with afterCare section */}
              <Row>
                {/* bs-col afterCare section */}
                <Col md="12">
                  {/* styled-component afterCareTitle and icon section */}
                  <SectionTitle>
                    {modalContent.servicesDetails.afterCareTitle}{" "}
                    {/* fontawesome Icon circleQuestion  */}
                    <FontAwesomeIcon
                      className="serviceTitleIcons"
                      icon={faHandHoldingMedical}
                    />
                  </SectionTitle>
                  {/* styled-components div afterCareBody and icon  */}
                  <SectionBody>
                    {/* fontAwesome circle icon */}
                    <FontAwesomeIcon
                      className="serviceBodyIcons"
                      icon={faBriefcaseMedical}
                    />
                    {/* afterCareBody */}
                    {modalContent.servicesDetails.afterCareBody}
                  </SectionBody>
                </Col>
              </Row>
              {/* fancy horizontal lineBreak */}
              <div className="service-fancy-divider">
                <div className="service-fancy-divider-shadow"></div>
              </div>
            </Container>
            {/* bs-container with processOfService section  */}
            <Container>
              {/* bs-row with processOfService section */}
              <Row>
                {/* bs-col with processOfService section */}
                <Col md="12">
                  {/* styled components div processOfServiceTitle section  */}
                  <SectionTitle>
                    {/* processOfServiceTitle */}
                    {modalContent.servicesDetails.processOfServiceTitle}
                    {/* fontawesome Icon circleQuestion  */}
                    <FontAwesomeIcon
                      className="serviceTitleIcons"
                      icon={faHourglassHalf}
                    />
                  </SectionTitle>
                  <SectionBody>
                    {/* fontAwesome circle icon */}
                    <FontAwesomeIcon
                      className="serviceBodyIcons"
                      icon={faPlus}
                    />
                    {modalContent.servicesDetails.processOfServiceBody}
                  </SectionBody>
                </Col>
              </Row>
              {/* fancy horizontal lineBreak */}
              <div className="service-fancy-divider">
                <div className="service-fancy-divider-shadow"></div>
              </div>
            </Container>
            {/* div holding the actionUrl Button  */}
            <div className="text-center">
              {/* booking url Button opening blank window  */}
              <Button
                id="BookNowPink"
                onClick={() => {
                  window.open(modalContent.bookingUrl);
                  // FB_Pixel AttToCart Pixel track event Service Screen course book modal-button
                  ReactPixel.track(`AddToCart`, {
                    id: "ServiceBookNowModalButton",
                    content_name: `${modalContent.serviceName}-BookNow-Modal`,
                    content_type: `${modalContent.serviceName}-BookNow-Modal`,
                  });
                  // FB convertion api AddToCArt for service book now
                  facebookConversion(
                    `${modalContent.serviceName}-BookNow-Modal`,
                    `AddToCart`
                  );
                  // reactga4 event for the service booking button
                  ReactGA.event({
                    name: `${modalContent.serviceName}`,
                    category: "Service Appointment Booking",
                    action: `Service Booking-${modalContent.serviceName}`,
                    label: `Service Book-Appt-Button-${modalContent.serviceName}`, // optional
                  });
                }}
              >
                {" "}
                Book Appointment{" "}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {/* =========END of MODAL=============== */}
      {/* ==========SERVICES SCREEN Start ================= */}
      <main id="main">
        {/* service screen separator div  */}
        <div id="SeparadorInicialServicesScreen"></div>
        <section id="services" className="section services section-bg">
          {/* bs-container for Services screen for the category and service cards */}
          <Container className="container">
            {/* Services header title  */}
            <h3> Services </h3>
            {/* styled-component-div for Services card  */}
            <ServicesCardMainDiv>
              {/* styled-component-div for service category picker  */}
              <ServicesCategoryDiv>
                {/* styled-component-div service category picker  */}
                <CategoryPicker>
                  {/* mapping through categories  */}
                  {Object.keys(ServicesData).map((category) => {
                    return (
                      // styled-component-div for service category
                      <Category
                        key={category}
                        isSelected={selectedCategory === category}
                        onClick={() => {
                          let url = "/services/";
                          switch (category) {
                            case "Permanent Make Up":
                              url += "permanentMakeup";
                              break;
                            case "Body Contouring":
                              url += "bodyContouring";
                              break;
                            case "Aesthetics":
                              url += "aesthetics";
                              break;
                            case "Teeth Whitening":
                              url += "teethWhitening";
                              break;

                            default:
                              url += "permanentMakeup";
                              break;
                          }
                          navigate(url);
                        }}
                      >
                        {category}
                      </Category>
                    );
                  })}
                </CategoryPicker>
                {/* styled-component-div for Service card */}
                <CardsContainer>
                  {/* mapping through for each Service in each categories  */}
                  {ServicesData[selectedCategory].map((service, index) => {
                    return (
                      //  styled-component-div Service card
                      <ServicesCard
                        key={`${service.title}-${index}`}
                        heading={service.serviceName}
                        image={service.image}
                        body={service.smallDescription}
                        price={service.price}
                        bookingUrl={service.bookingUrl}
                        serviceName={service.serviceName}
                        setIsModalOpen={() => {
                          setModalContent(service);
                          setIsModalOpen(true);
                          // react GA$ ever for Service details
                          ReactGA.event({
                            name: `${service.serviceName}`,
                            category: "Services",
                            action: `Service-Details-${service.serviceName}`,
                            label: `Service-Card-Details${service.serviceName}`, // optional
                          });
                        }}
                      />
                    );
                  })}
                </CardsContainer>
              </ServicesCategoryDiv>
            </ServicesCardMainDiv>
          </Container>
        </section>
      </main>
      {/* using the react-router Outlet  */}
      <Outlet />;
    </MainServiceScreenDiv>
  );
}

export default ServicesScreen;
//styling
const MainServiceScreenDiv = styled.div``;

const ServicesCardMainDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;
const ServicesCategoryDiv = styled.div`
  padding: 20px 0;
  margin: 20px 0px 150px 0px;
  overflow: hidden;
  position: relative;
`;
const CategoryPicker = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`;
const Category = styled.div`
  padding: 8px 25px;
  margin-bottom: 1em;
  border-radius: 4px;
  margin-left: 30px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? "#FFB339" : "#9C8068")};
  color: #fff;
  &:hover {
    color: #fff;
    background-color: #ffb339;
  }
`;
const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 1.2rem;
  padding-top: 10px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
`;
const SectionBody = styled.div`
  padding-left: 10px;
  line-height: 40px;
  font-size: 1rem;
`;
