// Importing the images
//PMU IMAGES
import LipBlush from "../../assets/images/services/LipBlushService.jpg";
import LipBlushTouchUp from "../../assets/images/services/LipBlushService.jpg";
import LipNeutralizationImg from "../../assets/images/services/lipNeutralization.jpg";
import PowderBrows from "../../assets/images/services/powderBrowsService.jpg";
import CombinationBrows from "../../assets/images/services/powderBrowsService.jpg";
import MicroBladingBrows from "../../assets/images/services/powderBrowsService.jpg";

//BODY CONTOURING IMAGES
import SelfCareSession from "../../assets/images/services/bodyContouringservice.jpg";
import MiniMommyMakeOver from "../../assets/images/services/bodyContouringTrace.jpg";
import UltimatePeachySession from "../../assets/images/services/bodyContouringservice.jpg";
import EMSlim from "../../assets/images/services/bodyContouringTrace.jpg";

//AESTHETICS images
import Botox from "../../assets/images/services/injectablesBotoxService.jpg";
import Filler from "../../assets/images/services/InjectablesFillerService.jpg";
import Dermaplaning from "../../assets/images/services/dermaPlanningService.jpg";
import Microneedling from "../../assets/images/services/microneedlingService.jpg";

//TEETH WHITENING IMAGES
import TeethWhiteningImg from "../../assets/images/services/teethWhiteningService.jpg";
import ToothGems from "../../assets/images/services/toothGemService.jpg";
// creating my data array
export const ServicesData = {
  //   ===========================================================================================================
  // PMU SECTION
  "Permanent Make Up": [
    //PMU LIP BLUSH
    {
      serviceId: "lipBlush",
      serviceName: "Lip Blush",
      image: LipBlush,
      alt: "Lip Blush Image",
      smallDescription:
        " Lip blushing is a semi-permanent tattoo that can enhance the color and shape of your lips.  Touch up is not included. ",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/lipblush",
      servicesDetails: {
        whatIsServiceTitle: "Lip Blush",
        whatIsServiceBody:
          " Lip blushing is a semipermanent makeup tattoo designed for your lips, which allows you to enhance your natural color and shape, or camouflage some minor corrections like asymmetry, scarring, or hyperpigmentation.",
        benefitsOfServiceTitle: "Benefits of Lip Blush",
        benefitsOfServiceBody:
          "Enhance the natural shape of your lips, correct asymmetry, add color and say “ I woke up like this.",
        afterCareTitle: "Lip Blushing After-Care",
        afterCareBody:
          "Keep lips moist at all times. Avoid oily, spicy and heavily seasoned food and drink with a straw. After every meal gently rinse and clean lips with water and pat dry, then re-apply ointment.",
        processOfServiceTitle: "Lip Blush Process",
        processOfServiceBody:
          "After your initial consultation, we will map and outline your lips, Numb with topical anesthesia, choose a fitting pigment and begin the shading process.",
      },
      price: "$220.00 + USD",
    },
    //  PMU  Lip Neutralization
    {
      serviceId: "lipNeutralization",
      serviceName: "Lip Neutralization",
      image: LipNeutralizationImg,
      alt: "Lip Neutralization",
      smallDescription:
        "Lip Neutralization is a unique and gradual process that works to both even-out lip tone as well as slightly brighten naturally darker lips. Touch up not included.",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/lipNeutralization",
      servicesDetails: {
        whatIsServiceTitle: "Lip Neutralization",
        whatIsServiceBody:
          "Lip Neutralization is a unique and gradual process that works to both even-out lip tone as well as slightly brighten naturally darker lips.",
        benefitsOfServiceTitle: "Benefits of Lip Neutralization",
        benefitsOfServiceBody:
          "Helps Correct Hyperpigmentation, uneven color and brightens your lips, Enhances the natural look to help your self glow every morning.",
        afterCareTitle: "Lip blushing After-Care",
        afterCareBody:
          "Keep lips moist at all times. Avoid oily, spicy and heavily seasoned food and drink with a straw. After every meal gently rinse and clean lips with water and pat dry, then re-apply ointment.",
        processOfServiceTitle: "Lip Neutralization Process",
        processOfServiceBody:
          "After your initial consultation, we will map and outline your lips, Numb with topical anesthesia, choose a fitting pigment and begin the shading process.",
      },
      price: "$220.00 + USD",
    },
    //  PMU  Lip Blush Touch Up
    {
      serviceId: "lipBlushTouchUp",
      serviceName: "Lip Blush Touch Up",
      image: LipBlushTouchUp,
      alt: "Lip Blush Touch Up Image",
      smallDescription:
        "This service is for current clients ONLY. Lip Blush Touch Ups are recommended after 8 weeks of your initial appointment, but must be within 6 months after your initial Appointment for the best results.",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/LipBlushTouchup",
      servicesDetails: {
        whatIsServiceTitle: "Lip Blush Touch-up",
        whatIsServiceBody:
          "Touch-ups are recommended 8 weeks after the initial lip blush appointment. These Touch-ups will allow the artist to implant more color and to perfect after healing.",
        benefitsOfServiceTitle: "Benefits of Lip Blush",
        benefitsOfServiceBody:
          "Touch-ups will give you a color boost after healing and fading over time.",
        afterCareTitle: "Lip blush Touch-up After-Care",
        afterCareBody:
          "Keep lips moist at all times. Avoid oily, spicy and heavily seasoned food and drink with a straw. After every meal gently rinse and clean lips with water and pat dry, then re-apply ointment.",
        processOfServiceTitle: "Lip Blush Touch-Up Process",
        processOfServiceBody:
          "We will re-outline your lips, Numb with topical anesthesia, choose a fitting pigment and begin the shading process.",
      },
      price: "$100.00 + USD",
    },
    // PMU Lip Neutralization Touch Up
    {
      serviceId: "lipNeutralizationTouchUp",
      serviceName: "Lip Neutralization Touch Up",
      image: LipNeutralizationImg,
      alt: "Lip Neutralization Touch Up Image",
      smallDescription:
        "This service is for current clients ONLY. Lip Neutralization Touch Ups are recommended after 8 weeks of your initial appointment, but must be within 6 months after your initial Appointment for the best results.",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/LipNeutralization",
      servicesDetails: {
        whatIsServiceTitle: "Lip Neutralization Touch Up",
        whatIsServiceBody:
          "Touch-ups are recommended 8 weeks after the initial lip blush appointment. These Touch-ups will allow the artist to implant more color and to perfect after healing.",
        benefitsOfServiceTitle: "Benefits Lip Neutralization Touch Up",
        benefitsOfServiceBody:
          "Touch-ups will give you a color boost after healing and fading over time. Enhances the natural look to help your self glow every morning",
        afterCareTitle: "Lip Neutralization Touch Up After-Care",
        afterCareBody:
          "Keep lips moist at all times. Avoid oily, spicy and heavily seasoned food and drink with a straw. After every meal gently rinse and clean lips with water and pat dry, then re-apply ointment.",
        processOfServiceTitle: "Lip Neutralization Touch Up Process",
        processOfServiceBody:
          "We will re-outline your lips, Numb with topical anesthesia, choose a fitting pigment and begin the shading process.",
      },
      price: "$100.00 + USD",
    },
    // PMU powder Brows!
    {
      serviceId: "powderBrows",
      serviceName: "Powder Brows",
      image: PowderBrows,
      alt: "Powder Brows Image",
      smallDescription:
        "Pixelated, Powdered Make-up look. Touch up is not included.",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/powderombrebrows",
      servicesDetails: {
        whatIsServiceTitle: "Powder Brows",
        whatIsServiceBody:
          "Powder brows is a cosmetic semi permanent makeup technique designed to create eyebrows with soft powder effect, very similar to powder makeup. Powder effect is done with a shading technique using a permanent makeup device, which is similar to a tattoo gun.",
        benefitsOfServiceTitle: "Benefits of Powder Brows",
        benefitsOfServiceBody:
          "Powder Brows will help give your brows more volume, save you time in the mornings,give a make-up look, are waterproof and will last up to two years.",
        afterCareTitle: "Powder Brows After-Care",
        afterCareBody:
          "NO MOISTURE! NO WATER! NO SWEAT! NO SUN! For 10 days. The best results come from the “Do Nothing” or “Dry Healing” method. The absolute best results come from keeping your brows extremely DRY for the next 10 days! Keeping them dry makes them retain more detail, better color, and more crisp strokes, so it’s worth it. It sounds tricky- but can be done if you’re mindful of it. Touch-up requires after 6 weeks of initial appointment. ",
        processOfServiceTitle: "Powder Brows Process",
        processOfServiceBody:
          "After your consult, we will begin to measure and shape your brows. We use your natural facial features and hair growth pattern. Once you approve of the shape, we use topical anesthesia for numbing, choose a fitting color and begin to shade in the brows with our tattoo pen. This process can take 2-3 hours.",
      },
      price: "$400.00 + USD",
    },

    // PMU MicroBlading!
    {
      serviceId: "microblading",
      serviceName: "Microblading",
      image: MicroBladingBrows,
      alt: "Microblading Brows Image",
      smallDescription:
        "Natural, Hair like strokes throughout the entire brow. Touch up is not included.",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/Microblading",
      servicesDetails: {
        whatIsServiceTitle: "Microblading",
        whatIsServiceBody:
          "Microblading, is a semi-permanent tattoo that creates hair-like strokes with the use of a manual pen containing very small needles. Not suitable for oily or thin skin.",
        benefitsOfServiceTitle: "Benefits of Microblading",
        benefitsOfServiceBody:
          "Microblading will help give your brows more volume, save you time in the mornings, are waterproof and will last up to two years",
        afterCareTitle: "Microblading After-Care",
        afterCareBody:
          "NO MOISTURE! NO WATER! NO SWEAT! NO SUN! For 10 days. The best results come from the “Do Nothing” or “Dry Healing” method. The absolute best results come from keeping your brows extremely DRY for the next 10 days! Keeping them dry makes them retain more detail, better color, and more crisp strokes, so it’s worth it. It sounds tricky- but can be done if you’re mindful of it. Touch-up requires after 6 weeks of initial appointment.",
        processOfServiceTitle: "Microblading Process",
        processOfServiceBody:
          "After your consult, we will begin to measure and shape your brows. We use your natural facial features and hair growth pattern. Once you approve of the shape, we use topical anesthesia for numbing, choose a fitting color and begin to create hair like strokes with our manual pen. This process can take 2-3 hours.",
      },
      price: "$400.00 + USD",
    },
    // PMU Combination Brows!
    {
      serviceId: "combinationBrows",
      serviceName: "Combination Brows",
      image: CombinationBrows,
      alt: "Combination Brows Image",
      smallDescription:
        "Hair-like strokes and Machine shading. Touch up is not included.",
      bookingUrl: " https://SolylunabeautiqueAustin.as.me/CombinationBrows",
      servicesDetails: {
        whatIsServiceTitle: "Combination Brows",
        whatIsServiceBody:
          "Combo brows are a combination of microblading and powder brows (shading). There are microblading hair-like strokes from the beginning to the middle of the brow to give the brow a natural look, which then transitions to a powdered effect to the body and tail of the brow to give the brow more fullness and density.",
        benefitsOfServiceTitle: "Benefits of Combination Brows",
        benefitsOfServiceBody:
          "Combination Brows will help give your brows more volume, save you time in the mornings,give a make-up look, are waterproof and will last up to two years.",
        afterCareTitle: "Microblading After-Care",
        afterCareBody:
          "NO MOISTURE! NO WATER! NO SWEAT! NO SUN! For 10 days.The best results come from the “Do Nothing” or “Dry Healing” method. The absolute best results come from keeping your brows extremely DRY for the next 10 days! Keeping them dry makes them retain more detail, better color, and more crisp strokes, so it’s worth it. It sounds tricky- but can be done if you’re mindful of it. Touch-up requires after 6 weeks of initial appointment.",
        processOfServiceTitle: "Combination Brows Process",
        processOfServiceBody:
          "After your consult, we will begin to measure and shape your brows. We use your natural facial features and hair growth pattern. Once you approve of the shape, we use topical anesthesia for numbing, choose a fitting color and begin to create hair like strokes and shading with our tattoo pen. This process can take 2-3 hours.",
      },
      price: "$400.00 + USD",
    },
    // PMU Brow Touch Up!
    {
      serviceId: "browTouchUp",
      serviceName: "Brow Touch-ups",
      image: PowderBrows,
      alt: "Brow Touch-ups Image",
      smallDescription:
        "This service is for current clients ONLY. Brow Touch-ups to refresh the color of the brows. Touch Ups are recommended after 8 weeks of your initial appointment, but must be within 6 months after your initial Appointment for the best results.",
      bookingUrl: "  https://SolylunabeautiqueAustin.as.me/BrowTouchUps",
      servicesDetails: {
        whatIsServiceTitle: "Brow Touch-Ups",
        whatIsServiceBody:
          "Brow Touch-ups are required 6 weeks after your initial appointment and as needed every year to give a color refresh. These appointments are shorter in length compared to the initial appointment.",
        benefitsOfServiceTitle: "Benefits of Combination Brows",
        benefitsOfServiceBody:
          "Combination Brows will help give your brows more volume, save you time in the mornings,give a make-up look, are waterproof and will last up to two years",
        afterCareTitle: "Touch-up After-Care",
        afterCareBody:
          "NO MOISTURE! NO WATER! NO SWEAT! NO SUN! For 10 days.The best results come from the “Do Nothing” or “Dry Healing” method. The absolute best results come from keeping your brows extremely DRY for the next 10 days! Keeping them dry makes them retain more detail, better color, and more crisp strokes, so it’s worth it. It sounds tricky- but can be done if you’re mindful of it.",
        processOfServiceTitle: "Brow Touch-Up Process",
        processOfServiceBody:
          "Six weeks after your initial appointment we will re-map your brows, numb them and begin the touch up process which is similar to your initial appointment. This process can take 1-2hours.",
      },
      price: "$100.00 + USD",
    },
  ],
  // ===========================================================================================================
  // Body contouring SECTION
  "Body Contouring": [
    // Self Care Session
    {
      serviceId: "selfCareSession",
      serviceName: "Self Care Session",
      image: SelfCareSession,
      alt: "Self Care Session Image",
      smallDescription:
        "Achieve your body goals through our all-inclusive body contouring session. Break down fat, tone, tighten loose skin and reduce the appearance of cellulite.",
      bookingUrl:
        "https://SolylunabeautiqueAustin.as.me/Signatureselfcaresession",
      servicesDetails: {
        whatIsServiceTitle: "Self Care Session",
        whatIsServiceBody:
          "This session will be customized to help you achieve your body goals.  Break down fat, Tighten loose skin, diminish the appearance of cellulite and attract microcirculation to the areas treated.  A mix of Cavitation, Vacuum Therapy , Radio frequency skin tightening, Laser-Lipo, EMS and Wood therapy can be used throughout the session. Sauna Detox is also included and optional.",
        benefitsOfServiceTitle:
          "Benefits of Non-Invasive Body Contouring sessions",
        benefitsOfServiceBody:
          "All of our Body Contouring sessions are non-invasive, pain-less, no down time and don’t require more than an hour of your time.",
        afterCareTitle:
          "How to benefit and obtain the best results after your session",
        afterCareBody:
          "For best results please drink a minimum of 64oz of water, Avoid alcohol and caffeine, Avoid high fat foods and sugars.",
        processOfServiceTitle: "Lets walk you through your appointment ",
        processOfServiceBody:
          "Upon arrival, you will be given a consultation form to fill out, this will help us understand your lifestyle and the goals you would like to achieve. We then give you our technique recommendations and get to work. To measure your results, we will measure the area to be treated and take before pictures (Don’t worry, we never include your face in these images and blur out any tattoos). We will make sure you know what techniques we’re using, how it feels and what its for before we start.",
      },
      price: "Starting at $185 + USD",
    },
    //BC Mini Mommy Makeover
    {
      serviceId: "miniMommyMakeover",
      serviceName: "Mini Mommy Makeover",
      image: MiniMommyMakeOver,
      alt: "Mini Mommy Makeover Image",
      smallDescription:
        "Achieve your body goals through our all-inclusive body contouring sessions. Break down fat, tone, tighten loose skin and reduce the appearance of cellulite",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/MiniMommyMakeover",
      servicesDetails: {
        whatIsServiceTitle: "Mini Mommy Makeover",
        whatIsServiceBody:
          "This 90 minute session will be customized to help you achieve your body goals Post- Partum. We will focus on flattening the tummy, Break down fat, Tighten loose skin, diminish the appearance of cellulite and attract microcirculation to the areas treated.  A mix of Cavitation, Vacuum Therapy , Radio frequency skin tightening, Laser-Lipo, EMS and Wood therapy can be used throughout the session. Sauna Detox is also included and optional. We will complete this session with a Yeso Therapy Wrap which will assist in losing inches around the waist.",
        benefitsOfServiceTitle:
          "Benefits of Non Invasive Body Contouring sessions",
        benefitsOfServiceBody:
          "All of our Body Contouring sessions are non-invasive, pain-less, no down time and don’t require more than an hour of your time.",
        afterCareTitle:
          "How to benefit and obtain the best results after your session",
        afterCareBody:
          "For best results please drink a minimum of 64oz of water, Avoid alcohol and caffeine, Avoid high fat foods and sugars.",
        processOfServiceTitle: "Lets walk you through your appointment",
        processOfServiceBody:
          "Upon arrival, you will be given a consultation form to fill out, this will help us understand your lifestyle and the goals you would like to achieve. We then give you our technique recommendations and get to work. To measure your results, we will measure the area to be treated and take before pictures (Don’t worry, we never include your face in these images and blur out any tattoos). We will make sure you know what techniques we’re using, how it feels and what its for before we start.",
      },
      price: "Starting at $225 + USD",
    },
    // Ultimate Peachy plump Session
    {
      serviceId: "ultimatePeachyPlumpSession",
      serviceName: "Ultimate Peachy Plump Session",
      image: UltimatePeachySession,
      alt: "Ultimate Peachy Plump Session Image",
      smallDescription:
        "Achieve your body goals through our all-inclusive body contouring session. Break down fat, tone, tighten loose skin and reduce the appearance of cellulite.",
      bookingUrl:
        "https://SolylunabeautiqueAustin.as.me/Ultimatepeachyplumpsession",
      servicesDetails: {
        whatIsServiceTitle: "Peachy Plump Session",
        whatIsServiceBody:
          "This session will be customized to help you achieve your body goals. With Peachy plump sessions, we are able to use Vacuum therapy cupping to help lift, provide a rounder appearance and help diminish the appearance of cellulite on the buttocks.In addition to cupping, we use cavitation, wood therapy and EMS to help with cellulite behind the thighs, fat loss and skin tightening.",
        benefitsOfServiceTitle:
          "Benefits of Non Invasive Body Contouring sessions",
        benefitsOfServiceBody:
          "All of our Body Contouring sessions are non-invasive, pain-less, no down time and don’t require more than an hour of your time.",
        afterCareTitle:
          "How to benefit and obtain the best results after your session",
        afterCareBody:
          "For best results please drink a minimum of 64oz of water, Avoid alcohol and caffeine, Perform lower body workouts and Avoid high fat foods and sugars.",
        processOfServiceTitle: "Lets walk you through your appointment",
        processOfServiceBody:
          "Upon arrival, you will be given a consultation form to fill out, this will help us understand your lifestyle and the goals you would like to achieve. We then give you our technique recommendations and get to work. To measure your results, we will measure the area to be treated and take before pictures (Don’t worry, we never include your face in these images and blur out any tattoos). We will make sure you know what techniques we’re using, how it feels and what its for before we start.",
      },
      price: "Starting at $185 + USD",
    },
  ],
  //  ===========================================================================================================
  //   Aesthetics Section
  Aesthetics: [
    // Botox
    {
      serviceId: "botox",
      serviceName: "Botox",
      image: Botox,
      alt: "Botox Img",
      smallDescription:
        "Say goodbye to those small lines and wrinkles! Neurotoxin injections can help diminish the appearance of fine lines and wrinkles in the forehead, between your brows and crows feet. ",
      bookingUrl: " https://SolylunabeautiqueAustin.as.me/Neurotoxin",
      servicesDetails: {
        whatIsServiceTitle: "What is Neurotoxin/Botox?",
        whatIsServiceBody: `Botulinum toxin type A is an injectable neurotoxin, better known under brand names such as Botox Cosmetic®, Dysport®, Xeomin®, and Jeuveau®. Neurotoxin treatments are the most popular single cosmetic procedure in the U.S. and are mainly used to smooth dynamic wrinkles, or those wrinkles that form as a result of facial movements like frowning, squinting, smiling, and other expressions we tend to make day after day. Treatment works by blocking nerve impulses to the muscles, causing them to relax. As a result, your expressions soften and the dynamic wrinkles are greatly reduced.`,
        benefitsOfServiceTitle: "Benefits of Neurotoxin injections",
        benefitsOfServiceBody: `Botox-type injectables can help an adult patient of any age improve his or her appearance, not only imparting a more youthful look, but also alleviating an overly concerned look, helping you look better refreshed or even more pleasant. The most commonly treated areas are those where muscle activity influences facial features, such as the following: Glabellar lines (a.k.a. "elevens" or frown lines), Wrinkles around the eyes, such as crow's feet, Forehead creases, Corners of the mouth, A "cobblestoned" appearance to the chin.`,
        afterCareTitle:
          "How to benefit and obtain the best results after your session",
        afterCareBody:
          "You will be able to go back to your normal activities right after treatment, but your cosmetic surgeon may ask you to take the day off from exercise or other strenuous activity, and to keep your head upright for the rest of the day. Mild redness, swelling at the injection site, and bruising are the most common side effects.",
        processOfServiceTitle: "Lets walk you through your appointment",
        processOfServiceBody:
          "Just before the actual treatment, you may be given a topical anesthetic to numb the area prior to injection, though many patients find this unnecessary, as the needles used to inject botulinum toxins are very thin and the injections are not very deep. Your provider will reconstitute the botulinum toxin into a solution, and inject this solution into the muscle at strategic points. Most patients describe a pinching feeling during the injection, though not much pain.",
      },
      price: "Starting at $12/unit",
    },

    // Derma Filler
    {
      serviceId: "dermaFiller",
      serviceName: "Dermal Filler",
      image: Filler,
      alt: "Dermal Filler Img",
      smallDescription:
        " Enhance your natural beauty with some filler. It can be used for lip, cheek and chin augmentation. Smooth out deep lines in the nasolabial folds and Marionette lines in one simple appointment.",
      bookingUrl: " https://SolylunabeautiqueAustin.as.me/filler",
      servicesDetails: {
        whatIsServiceTitle: "What is Dermal Filler?",
        whatIsServiceBody:
          "Dermal fillers are gel-like substances that are injected beneath the skin to restore lost volume, smooth lines and soften creases, or enhance facial contours.",
        benefitsOfServiceTitle: "Benefits of Dermal filler.",
        benefitsOfServiceBody:
          'While dermal fillers are casually known as "wrinkle fillers," they can do much more than just smooth out wrinkles, although they are excellent at this too! Here are a few of the common issues dermal fillers can help address: Smooth out lines around nose and mouth (a.k.a. marionette lines, smile lines, and parentheses), Enhance & restore volume to sunken cheeks or temples, Diminish vertical lip lines, Plump & enhance the lips, Smooth out a chin crease, improve symmetry among facial features.',
        afterCareTitle:
          "How to benefit and obtain the best results after your session.",
        afterCareBody:
          "Depending on the product and the areas treated, you should be able to notice results immediately after receiving filler injections. Some patients experience mild bruising and swelling, but these are temporary and should subside over the days following treatment. You will be able to go back to your normal activities right after treatment, but your cosmetic surgeon may ask you to take the day off from exercise or other strenuous activity.",
        processOfServiceTitle: "Lets walk you through your appointment.",
        processOfServiceBody:
          "Once We have ruled out any contraindications, We will begin my numbing the area to be treated, this process usually take 15-20 minutes. Depending on the area being treated, the procedure can take 20-40 minutes.",
      },
      price: "Starting at $325+",
    },
    // DermaPlaning
    {
      serviceId: "dermaplaning",
      serviceName: "Dermaplaning",
      image: Dermaplaning,
      alt: "Dermaplaning Img",
      smallDescription:
        "Obtain smooth, soft and glass like skin through a Dermaplane, this noninvasive procedure will remove dead skin cells, promote cell regeneration and remove vellus hair.",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/SignatureDermaplane",
      servicesDetails: {
        whatIsServiceTitle: "What is a Signature Dermaplane.",
        whatIsServiceBody:
          "This 60 minute session will include Radio Frequency for skin tightening and anti-aging factors. The dermaplane procedure consists of using a surgical scalpel to gently and safely exfoliate dead skin cells and vellus hair. Top off your dermaplane with a hydrating jelly mask and enjoy some facial wood therapy to complete the session.",
        benefitsOfServiceTitle: "Benefits of a Signature Dermaplane.",
        benefitsOfServiceBody:
          "Radio frequency helps tighten the skin and promotes the production of collagen and hyaluronic acid in the skin. Dermaplaning will mechanically exfoliate dead skin cells and will promote skin regeneration leaving your skin refreshed, soft and smooth. Your makeup application is going to be the best you’ve ever seen it and your skin will absorb those yummy serums and products a lot better. Its a win-win all around.",
        afterCareTitle:
          "How to benefit and obtain the best results after your session.",
        afterCareBody:
          "For great skin, stay hydrated, make sure to wear SPF daily, Use clean pillow cases and avoid touching your face . We recommend waiting 24 hours before applying makeup.",
        processOfServiceTitle: "Lets walk you through your appointment.",
        processOfServiceBody:
          "Upon arrival, you will be given a consultation form to fill out, this will help us understand your skin care routine and check for any contraindications. We will go over any recommendations and begin your pampering session.",
      },
      price: "Starting at $120+",
    },
    // Microneedling
    {
      serviceId: "microneedling",
      serviceName: "Microneedling",
      image: Microneedling,
      alt: "Microneedling Img",
      smallDescription:
        "Minimize the appearance of wrinkles, fine lines, acne scarring, pores  and induce the production of collagen in just an hour!",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/Microneedling",
      servicesDetails: {
        whatIsServiceTitle: "What is Microneedling.",
        whatIsServiceBody:
          "Microneedling is a cosmetic procedure. It involves pricking the skin with tiny sterilized needles. The small wounds cause your body to make more collagen and elastin, which heal your skin and help you look younger. You might also hear it called collagen induction therapy.",
        benefitsOfServiceTitle: "Benefits of Microneedling.",
        benefitsOfServiceBody:
          "Microneedling can help improve the appearance and texture of your skin. It can minimize stretch marks, scars, wrinkles and other skin concerns. It doesn't carry the risk of skin discoloration, unlike some facial rejuvenation procedures that use heat, light or lasers.",
        afterCareTitle: "Microneedling After-care",
        afterCareBody:
          "Immediately After Treatment: Cleanse with a mild soap daily. Gently pat dry. If your skin feels dry or tight, apply a gentle moisturizer. Avoid partaking in activities that will heat up your skin, such as going in a hot tub/Jacuzzi/sauna, taking a very hot shower, or strenuous exercise for 2 days after treatment.",
        processOfServiceTitle: "Lets walk you through your appointment.",
        processOfServiceBody:
          "Upon arrival, you will be given a consultation form to fill out, this will help us understand your lifestyle and to check for any contraindications.The professional performing the procedure will apply a very thick layer of numbing cream, to ensure that you don’t feel (most of) the pain from the needles penetrating your skin’s surface. The cream will be left on your skin for at least 15 minutes before your microneedling session begins. Your technician will wipe it away in sections to support maximum numbing throughout the treatment.",
      },
      price: "Starting at $179+",
    },
  ],
  //   ===========================================================================================================
  // Teeth Whitening SECTION
  "Teeth Whitening": [
    // Teeth Whitening
    {
      serviceId: "teethWhitening",
      serviceName: "Teeth Whitening",
      image: TeethWhiteningImg,
      alt: "Teeth Whitening Img",
      smallDescription: `Shine bright like a diamond and let your smile light up the room. Our Teeth whitening services will give you instant results. Perfect for personal events or simple up-keep.`,
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/TeethWhitening",
      servicesDetails: {
        whatIsServiceTitle: "What is Teeth Whitening?",
        whatIsServiceBody: `Teeth whitening involves the use of professional whitening dental materials to bleach/brighten your teeth. We offer short sessions as an introduction for those who have sensitive teeth.`,
        benefitsOfServiceTitle: "Benefits",
        benefitsOfServiceBody: `Remove Discoloration, Remove Stains, Improve Your Self-Esteem, Smile More Often, Look More Youthful, Hide Imperfections, Be Prepared for that Big Event, It's Easy, It's Quick.`,
        afterCareTitle: "How do you keep your smile bright after your session.",
        afterCareBody:
          "After you've had your teeth whitened, avoid any foods that could cause your teeth to stain for at least two days after you've had your procedure. This also includes smoking and chewing tobacco products, as these activities can also leave marks and stains on your teeth. When you have bleaching done professionally, it can make your teeth even more porous they were to begin with. This makes them easier to stain, and it makes it all the more important to avoid these foods and activities that can stain your teeth. While you should wait 48 hours to resume any activities or foods that could stain your teeth, it's best to wait for an entire week. To maximize your whitening results, you should also limit these foods and activities in the future. ",
        processOfServiceTitle: "What to expect at your appointment.",
        processOfServiceBody: `During your appointment, we will provide you with a medical/dental questionnaire and will assure there aren't any contraindications. Once you're in the chair, your technician will determine your starting tooth shade, take pictures and prep your smile with a cheek retractor, gingival barrier and apply the whitening solution. You will be under a special light for the duration of your appointment, 30 minutes- 90 minutes.`,
      },
      price: "Starting at $99.00 USD",
    },
    // Tooth Gems
    {
      serviceId: "toothGems",
      serviceName: "Tooth Gems",
      image: ToothGems,
      alt: "Tooth Gems Img",
      smallDescription: "Decorate your smile with some Bling.",
      bookingUrl: "https://SolylunabeautiqueAustin.as.me/ToothGems",
      servicesDetails: {
        whatIsServiceTitle: "Tooth Gems",
        whatIsServiceBody:
          "Tooth gems are made of different shapes and materials. Choose from solid 18k gold or Lead free Swarovski  Crystals. We carry different shapes and colors and sizes and accept requests for custom ones too. We use quality dental materials that are safe to use on Natural teeth.",
        benefitsOfServiceTitle:
          "What are the benefits of getting tooth gems placed by us",
        benefitsOfServiceBody:
          "Our Technicians have over 12 years of experience in the Dental field and will practice only the best sanitation and disinfection protocols. We know where tooth gems can be placed without causing any damage to opposing or adjacent teeth. We will never place a gem somewhere just because it will look pretty without making sure that it is safe.",
        afterCareTitle: "How to make sure your tooth gems last a while.",
        afterCareBody:
          "Avoid very crunchy foods, brush and maintain the area very clean to avoid plaque buildup around the gem and lastly, do not pick at the gem.",
        processOfServiceTitle: "Lets walk you through your appointment.",
        processOfServiceBody:
          " Upon arrival, you will be given a consultation form to fill out, this will help us understand if there are any contraindications and discuss placement.",
      },
      price: "Starting at $45+",
    },
  ],
};
