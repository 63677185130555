import TaniaPelagio from "../../assets/images/team/TaniaBioHeadShot600x600.jpg";
import ErikPelagio from "../../assets/images/team/ErikBioHeadShot600x600.jpg";

export const TeamData = {
  employees: [
    {
      employeeFirst: "Tania",
      employeeName: "Tania Pelagio",
      employeeTitle: "Chief Executive Officer",
      employeeImg: TaniaPelagio,
      employeeBioOne:
        "My name is Tania, I am your Certified Advanced Injector and lead instructor for SyL and SyL Academy. I started Sol y Luna in my imagination at a very young age. Growing up in a salon fueled my passion for creative art and Beauty. I strive to provide an inclusive and comfortable space for you to treat yourself through many of our self care services. ",
      employeeBioTwo:
        "I've worked in the Dental field for over 12 years and in the past four years have taken over 20 certification courses to provide you with quality services. When I'm not at SyL, I enjoy spending time with family, Traveling, trying new foods and watching tiktoks. ",
    },
    {
      employeeFirst: "Erik Sr",
      employeeName: "Erik Pelagio Sr",
      employeeTitle: "Business Manager",
      employeeImg: ErikPelagio,
      employeeBioOne:
        "Hi there! My name is Erik, I am the business manager at SyL. To be honest, I feel like I was involuntarily given this role by my wife, Tania. Nevertheless, I loved it! Since then, I've been told that I adapted and evolved into the role effortlessly. I am responsible for managing, ordering and developing our website. I also manage some of the social media. ",
      employeeBioTwo:
        "I attended UT Austin for Web Development and also worked in the recruiting department for certain cybersecurity and IU/IX positions.  I also have over twelve years of Dental and Orthodontic experience. When the boss gives time off, I enjoy creating memories with family, watching sports and travel.",
    },
  ],
};
